import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILURE,
  CLEAR_CART_ITEMS,
} from '../actions/cartActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  total_quantity: 0,
  total_price: 0,
  current_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_REQUEST:
    case ADD_TO_CART_REQUEST:
    case UPDATE_CART_REQUEST:
    case REMOVE_FROM_CART_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_CART_SUCCESS:
    case ADD_TO_CART_SUCCESS:
    case UPDATE_CART_SUCCESS:
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.cart_items,
        total_quantity: action.payload.total_quantity,
        total_price: action.payload.total_price,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case FETCH_CART_FAILURE:
    case ADD_TO_CART_FAILURE:
    case UPDATE_CART_FAILURE:
    case REMOVE_FROM_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_CART_ITEMS:
      return {
        ...state,
        items: [],
        total_quantity: 0,
        total_price: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
