import axiosInstance from '../utils/axiosInstance';
import Cookies from 'js-cookie';
import axios from 'axios';

export const login = async (identifier, password) => {
  const response = await axiosInstance.post('/login', { identifier, password });
  if (response.data.valid === 1) {
    const token = response.data.token;
    const user = response.data.user;
    Cookies.set('authToken', token, { expires: 30 });
    return { valid: 1, token, user, message: response.data.message };
  } else {
    return { valid: 0, message: response.data.message, errors: response.data.errors };
  }
};

export const register = async ({ fullName, username, whatsapp, email, password }) => {
  const response = await axiosInstance.post('/register', { fullName, username, whatsapp, email, password });
  if (response.data.valid === 1) {
    return { valid: 1, message: response.data.message };
  } else {
    return { valid: 0, message: response.data.message, errors: response.data.errors };
  }
};


export const activateAccount = async (username, verification_code) => {
  const response = await axiosInstance.post('/activate', { username, verification_code });
  if (response.data.valid === 1) {
    const token = response.data.token;
    const user = response.data.user;
    Cookies.set('authToken', token, { expires: 30 });
    return { valid: 1, token, user, message: response.data.message };
  } else {
    return { valid: 0, message: response.data.message, errors: response.data.errors };
  }
};


export const socialLogin = async (userData) => {
  const response = await axiosInstance.post('/auth/social', userData);
  const token = response.data.token;
  const user = response.data.user;

  Cookies.set('authToken', token, { expires: 30 });

  return { token, user };
};

export const handleGoogleSuccess = async (credential) => {
  const googleUser = await axios.post('https://oauth2.googleapis.com/tokeninfo', null, {
    params: {
      id_token: credential
    }
  });
  const { name, email, sub } = googleUser.data;

  const userData = {
    name,
    email,
    provider_id: sub,
    provider: 'google',
    password: generateRandomPassword()
  };

  return socialLogin(userData);
};

export const handleFacebookResponse = async (response) => {
  const facebookUser = await axios.get(`https://graph.facebook.com/me?access_token=${response.accessToken}&fields=name,email`);
  const { name, email, id } = facebookUser.data;

  const userData = {
    name,
    email,
    provider_id: id,
    provider: 'facebook',
    password: generateRandomPassword()
  };

  return socialLogin(userData);
};

export const generateRandomPassword = (length = 12) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let password = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};


export const getUserProfile = async () => {
  const response = await axiosInstance.get('/user/data');
  return response.data;
};

export const updateUserProfileService = async (updatedData) => {
  const response = await axiosInstance.post('/user/update', updatedData);
  return response.data;
};

export const updateUserPasswordService = async (passwordData) => {
  const response = await axiosInstance.post('/user/update-password', passwordData);
  return response.data;
};

export const logoutService = async () => {
  const response = await axiosInstance.post('/logout');
  return response.data;
};