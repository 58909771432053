import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const MetaTags = ({ title, description }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const metaTags = {
    en: {
      defaultTitle: "Ryozi",
      description: "Ryozi - The best online store in Saudi Arabia",
      keywords: "Ryozi, online store, Saudi Arabia, shopping, products",
      ogTitle: "Ryozi",
      ogDescription: "Ryozi - The best online store in Saudi Arabia",
      twitterTitle: "Ryozi",
      twitterDescription: "Ryozi - The best online store in Saudi Arabia",
    },
    ar: {
      defaultTitle: "ريوزي",
      description: "ريوزي - أفضل متجر إلكتروني في السعودية",
      keywords: "ريوزي, متجر إلكتروني, السعودية, تسوق, منتجات",
      ogTitle: "ريوزي",
      ogDescription: "ريوزي - أفضل متجر إلكتروني في السعودية",
      twitterTitle: "ريوزي",
      twitterDescription: "ريوزي - أفضل متجر إلكتروني في السعودية",
    }
  };

  const tags = metaTags[lang] || metaTags.en;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title ? `${title} - ${tags.defaultTitle}` : tags.defaultTitle}</title>
      <meta name="description" content={description || tags.description} />
      <meta name="keywords" content={tags.keywords} />
      <meta property="og:title" content={tags.ogTitle} />
      <meta property="og:description" content={tags.ogDescription} />
      <meta property="twitter:title" content={tags.twitterTitle} />
      <meta property="twitter:description" content={tags.twitterDescription} />
    </Helmet>
  );
};

export default MetaTags;
