export const getLangAndCurrency = () => {
    const currency = localStorage.getItem('selectedCurrency') || 'USD';
   
    return {currency };
  };
  
  export const getCookieId = () => {
    let cookieId = localStorage.getItem('cookie_id');
    if (!cookieId) {
      cookieId = ''; 
      localStorage.setItem('cookie_id', cookieId);
    }
    return cookieId;
  };
  
  export const setCookieId = (cookieId) => {
    localStorage.setItem('cookie_id', cookieId);
  };
  