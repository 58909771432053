import axiosInstance from '../utils/axiosInstance';
import { getCache, setCache } from '../utils/cache';

export const getCategories = async () => {
  const cacheKey = 'categories';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/categories');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await axiosInstance.get(`/categories/${id}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching category:', error);
    throw error;
  }
};

export const getCategoriesWithAllChildren = async () => {
  const cacheKey = 'categoriesWithAllChildren';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/categories/with-all/children');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching categories with all children:', error);
    throw error;
  }
};
