import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFavorites, removeFavorite } from '../../redux/actions/favoriteActions';
import { getFullImageUrl } from '../../utils/imageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MetaTags from '../../components/MetaTags';

const FavoritesPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { favorites, loading, error } = useSelector((state) => state.favorites);

  useEffect(() => {
    dispatch(fetchFavorites());
  }, [dispatch]);

  const handleRemoveFavorite = (productId) => {
    dispatch(removeFavorite(productId));
  };

  const handleRowClick = (productId) => {
    navigate(`/product/${productId}`);
  };


  return (
    <>
     <MetaTags title={t('Favorites')} />
    <div className="container mx-auto mt-8 px-4">
      {loading && <p className="text-center text-lg">{t('favorites.loading')}</p>}
      {error && <p className="text-center text-lg text-red-600">{t('favorites.error')}: {error}</p>}
      {!loading && !error && favorites.length === 0 && <p className="text-center text-lg">{t('favorites.no_favorites')}</p>}
      {!loading && !error && favorites.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.id')}</th>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.image')}</th>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.title')}</th>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.description')}</th>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.is_new')}</th>
                <th className="py-3 px-4 bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">{t('favorites.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {favorites.map((product) => (
                <tr key={product.id} className="border-b hover:bg-gray-100" onClick={() => handleRowClick(product.id)}>
                  <td className="py-3 px-4">{product.id}</td>
                  <td className="py-3 px-4">
                    <img src={getFullImageUrl(product.product_image)} alt={product.product_ar_title} className="w-16 h-16 object-cover rounded-lg" />
                  </td>
                  <td className="py-3 px-4">{i18n.language === 'ar' ? product.ar_title : product.en_title}</td>
                  <td className="py-3 px-4">{i18n.language === 'ar' ? product.ar_description : product.en_description}</td>
                  <td className="py-3 px-4">{product.is_new ? t('yes') : t('no')}</td>
                  <td className="py-3 px-4">
                    <button onClick={() => handleRemoveFavorite(product.id)} className="text-red-500 hover:text-red-700">
                      <FontAwesomeIcon icon={faHeart} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </>
  );
};

export default FavoritesPage;
