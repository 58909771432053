import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage).then(() => {
      localStorage.setItem('i18nextLng', newLanguage); 
      if (newLanguage === 'ar') {
        document.documentElement.dir = 'rtl';
        document.documentElement.lang = 'ar';
      } else {
        document.documentElement.dir = 'ltr';
        document.documentElement.lang = 'en';
      }
    });
  }; 

  return (
    <button onClick={toggleLanguage} className="text-lg font-bold text-gray-700">
      {i18n.language === 'en' ? 'ع' : 'En'}
    </button>
  );
}

export default LanguageSwitcher;
