import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchUserProfile, updateUserProfile, updateUserPassword } from '../../redux/actions/authActions'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from '../../components/MetaTags';
import './ProfilePage.css';
import { getFullImageUrl, defaultAvatar } from "../../utils/imageUtils";

const ProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      setFullName(profile.user.fullName);
      setUsername(profile.user.username);
      setWhatsapp(profile.user.whatsapp);
      setEmail(profile.user.email);
      setAvatar(profile.user.avatar);
    }
  }, [profile]);

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('username', username);
    formData.append('whatsapp', whatsapp);
    formData.append('email', email);
    if (avatarFile) {
      formData.append('avatar', avatarFile);
    }
    dispatch(updateUserProfile(formData));
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    const passwordData = { current_password: currentPassword, new_password: newPassword, new_password_confirmation: confirmPassword };
    dispatch(updateUserPassword(passwordData));
    setIsPasswordModalOpen(false);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatarFile(file);
    setAvatar(URL.createObjectURL(file));
  };

  return (
    <>
      <MetaTags title={t('profile')} />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-4">{t('profile')}</h1>
        <div className="flex flex-col items-center mb-4">
          <div className="relative">
            <img
              src={avatar ? getFullImageUrl(avatar) : defaultAvatar}
              alt="Avatar"
              className="w-24 h-24 rounded-full object-cover"
            />
            <label htmlFor="avatar-upload" className="absolute bottom-0 right-0 bg-blue-500 text-white rounded-full p-2 cursor-pointer">
              <i className="fas fa-edit"></i>
            </label>
            <input
              id="avatar-upload"
              type="file"
              className="hidden"
              onChange={handleAvatarChange}
            />
          </div>
        </div>
        <form onSubmit={handleProfileUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('fullName')}</label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('username')}</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('whatsapp')}</label>
            <input
              type="text"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('email')}</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            {t('updateProfile')}
          </button>
        </form>
        <button
          className="mt-4 w-full bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
          onClick={() => setIsPasswordModalOpen(true)}
        >
          {t('changePassword')}
        </button>
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">{t('userStatistics')}</h2>
          <ul className="list-disc list-inside">
            <li>{t('totalOrders')}: {profile?.orders_count || 0}</li>
            <li>{t('pendingOrders')}: {profile?.pending_orders_count || 0}</li>
            <li>{t('completedOrders')}: {profile?.completed_orders_count || 0}</li>
            <li>{t('cancelledOrders')}: {profile?.cancelled_orders_count || 0}</li>
            <li>{t('returnProductOrders')}: {profile?.return_product_orders_count || 0}</li>
            <li>{t('totalFavorites')}: {profile?.total_favorites || 0}</li>
            <li>{t('totalReviews')}: {profile?.total_reviews || 0}</li>
            <li>{t('accountCreatedAt')}: {profile ? new Date(profile.user.created_at).toLocaleDateString() : ''}</li>
          </ul>
        </div>
      </div>
      <ToastContainer />

      {isPasswordModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl mb-4">{t('changePassword')}</h2>
            <form onSubmit={handlePasswordUpdate}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('currentPassword')}</label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('newPassword')}</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('confirmNewPassword')}</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                {t('updatePassword')}
              </button>
              <button
                type="button"
                className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                onClick={() => setIsPasswordModalOpen(false)}
              >
                {t('cancel')}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
