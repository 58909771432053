import { getCurrencies, getSecondSlides, getSlides, getRandomAd,  testimonials as fetchTestimonialsApi , calculateCartTotal } from '../../services/appApi';

export const FETCH_SLIDES_REQUEST = 'FETCH_SLIDES_REQUEST';
export const FETCH_SLIDES_SUCCESS = 'FETCH_SLIDES_SUCCESS';
export const FETCH_SLIDES_FAILURE = 'FETCH_SLIDES_FAILURE';

export const FETCH_SECOND_SLIDES_REQUEST = 'FETCH_SECOND_SLIDES_REQUEST';
export const FETCH_SECOND_SLIDES_SUCCESS = 'FETCH_SECOND_SLIDES_SUCCESS';
export const FETCH_SECOND_SLIDES_FAILURE = 'FETCH_SECOND_SLIDES_FAILURE';

export const FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';

export const FETCH_RANDOM_AD_REQUEST = 'FETCH_RANDOM_AD_REQUEST';
export const FETCH_RANDOM_AD_SUCCESS = 'FETCH_RANDOM_AD_SUCCESS';
export const FETCH_RANDOM_AD_FAILURE = 'FETCH_RANDOM_AD_FAILURE';


export const FETCH_TESTIMONIALS_REQUEST = 'FETCH_TESTIMONIALS_REQUEST';
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS';
export const FETCH_TESTIMONIALS_FAILURE = 'FETCH_TESTIMONIALS_FAILURE';

export const CALCULATE_CART_TOTAL_REQUEST = 'CALCULATE_CART_TOTAL_REQUEST';
export const CALCULATE_CART_TOTAL_SUCCESS = 'CALCULATE_CART_TOTAL_SUCCESS';
export const CALCULATE_CART_TOTAL_FAILURE = 'CALCULATE_CART_TOTAL_FAILURE';

export const fetchSlides = () => async (dispatch) => {
  dispatch({ type: FETCH_SLIDES_REQUEST });
  try {
    const slides = await getSlides();
    dispatch({ type: FETCH_SLIDES_SUCCESS, payload: slides });
  } catch (error) {
    dispatch({ type: FETCH_SLIDES_FAILURE, payload: error.message });
  }
};

export const fetchSecondSlides = () => async (dispatch) => {
  dispatch({ type: FETCH_SECOND_SLIDES_REQUEST });
  try {
    const secondSlides = await getSecondSlides();
    dispatch({ type: FETCH_SECOND_SLIDES_SUCCESS, payload: secondSlides });
  } catch (error) {
    dispatch({ type: FETCH_SECOND_SLIDES_FAILURE, payload: error.message });
  }
};

export const fetchCurrencies = () => async (dispatch) => {
  dispatch({ type: FETCH_CURRENCIES_REQUEST });
  try {
    const currencies = await getCurrencies();
    dispatch({ type: FETCH_CURRENCIES_SUCCESS, payload: currencies });
  } catch (error) {
    dispatch({ type: FETCH_CURRENCIES_FAILURE, payload: error.message });
  }
};

export const fetchRandomAd = () => async (dispatch) => {
  dispatch({ type: FETCH_RANDOM_AD_REQUEST });
  try {
    const ad = await getRandomAd();
    dispatch({ type: FETCH_RANDOM_AD_SUCCESS, payload: ad });
  } catch (error) {
    dispatch({ type: FETCH_RANDOM_AD_FAILURE, payload: error.message });
  }
};

export const fetchTestimonials = () => async (dispatch) => {
  dispatch({ type: FETCH_TESTIMONIALS_REQUEST });

  try {
    const testimonials = await fetchTestimonialsApi();
    dispatch({ type: FETCH_TESTIMONIALS_SUCCESS, payload: testimonials });
  } catch (error) {
    dispatch({ type: FETCH_TESTIMONIALS_FAILURE, payload: error.message });
  }
};


export const calculateCartTotalAction = (currency, cookieId) => async (dispatch) => {
  dispatch({ type: CALCULATE_CART_TOTAL_REQUEST });
  try {
    const result = await calculateCartTotal(currency, cookieId);
    dispatch({ type: CALCULATE_CART_TOTAL_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: CALCULATE_CART_TOTAL_FAILURE, payload: error.message });
  }
};
