import {
  FETCH_SLIDES_REQUEST,
  FETCH_SLIDES_SUCCESS,
  FETCH_SLIDES_FAILURE,
  FETCH_SECOND_SLIDES_REQUEST,
  FETCH_SECOND_SLIDES_SUCCESS,
  FETCH_SECOND_SLIDES_FAILURE,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  FETCH_RANDOM_AD_REQUEST,
  FETCH_RANDOM_AD_SUCCESS,
  FETCH_RANDOM_AD_FAILURE,
  FETCH_TESTIMONIALS_REQUEST,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAILURE,
  CALCULATE_CART_TOTAL_REQUEST,
  CALCULATE_CART_TOTAL_SUCCESS,
  CALCULATE_CART_TOTAL_FAILURE,
} from '../actions/appActions';

const initialState = {
  slides: [],
  secondSlides: [],
  currencies: [],
  cartTotal: {
    total_price: 0,
    total_quantity: 0,
    currency: '',
  },
  randomAd: null,
  testimonials: [],
  loading: false,
  error: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SLIDES_REQUEST:
    case FETCH_SECOND_SLIDES_REQUEST:
    case FETCH_CURRENCIES_REQUEST:
    case FETCH_RANDOM_AD_REQUEST:
    case FETCH_TESTIMONIALS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SLIDES_SUCCESS:
      return { ...state, loading: false, slides: action.payload };
    case FETCH_SECOND_SLIDES_SUCCESS:
      return { ...state, loading: false, secondSlides: action.payload };
    case FETCH_CURRENCIES_SUCCESS:
      return { ...state, loading: false, currencies: action.payload };
    case FETCH_RANDOM_AD_SUCCESS:
      return { ...state, loading: false, randomAd: action.payload };
    case FETCH_TESTIMONIALS_SUCCESS:
      return { ...state, loading: false, testimonials: action.payload };
    case CALCULATE_CART_TOTAL_REQUEST:
      return { ...state, loading: true, error: null };
    case CALCULATE_CART_TOTAL_SUCCESS:
      return { ...state, loading: false, cartTotal: action.payload };
    case CALCULATE_CART_TOTAL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_SLIDES_FAILURE:
    case FETCH_SECOND_SLIDES_FAILURE:
    case FETCH_CURRENCIES_FAILURE:
    case FETCH_RANDOM_AD_FAILURE:
    case FETCH_TESTIMONIALS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default appReducer;
