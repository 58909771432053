import { getProducts, getSectionProducts, getProductsByCategory } from '../../services/api';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FETCH_SECTION_PRODUCTS_REQUEST = 'FETCH_SECTION_PRODUCTS_REQUEST';
export const FETCH_SECTION_PRODUCTS_SUCCESS = 'FETCH_SECTION_PRODUCTS_SUCCESS';
export const FETCH_SECTION_PRODUCTS_FAILURE = 'FETCH_SECTION_PRODUCTS_FAILURE';
export const FETCH_PRODUCTS_BY_CATEGORY_REQUEST = 'FETCH_PRODUCTS_BY_CATEGORY_REQUEST';
export const FETCH_PRODUCTS_BY_CATEGORY_SUCCESS = 'FETCH_PRODUCTS_BY_CATEGORY_SUCCESS';
export const FETCH_PRODUCTS_BY_CATEGORY_FAILURE = 'FETCH_PRODUCTS_BY_CATEGORY_FAILURE';

export const fetchProductsRequest = () => ({
  type: FETCH_PRODUCTS_REQUEST,
});

export const fetchProductsSuccess = (products, total) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products, total },
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: error,
});

export const fetchSectionProductsRequest = () => ({
  type: FETCH_SECTION_PRODUCTS_REQUEST,
});

export const fetchSectionProductsSuccess = (sections) => ({
  type: FETCH_SECTION_PRODUCTS_SUCCESS,
  payload: sections,
});

export const fetchSectionProductsFailure = (error) => ({
  type: FETCH_SECTION_PRODUCTS_FAILURE,
  payload: error,
});

export const fetchProductsByCategoryRequest = () => ({
  type: FETCH_PRODUCTS_BY_CATEGORY_REQUEST,
});

export const fetchProductsByCategorySuccess = (products) => ({
  type: FETCH_PRODUCTS_BY_CATEGORY_SUCCESS,
  payload: products,
});

export const fetchProductsByCategoryFailure = (error) => ({
  type: FETCH_PRODUCTS_BY_CATEGORY_FAILURE,
  payload: error,
});

export const fetchProducts = ({
  priceFrom,
  priceTo,
  nameFilter,
  selectedCategory,
  selectedSubcategory,
  selectedSubsubcategory,
  section = null, 
  page,
  limit,
}) => {
  return async (dispatch) => {
    dispatch(fetchProductsRequest());
    try {
      const response = await getProducts({
        priceFrom,
        priceTo,
        nameFilter,
        selectedCategory,
        selectedSubcategory,
        selectedSubsubcategory,
        section, 
        page,
        limit,
      });
      dispatch(fetchProductsSuccess(response.data, response.total));
      return { data: response.data, total: response.total };
    } catch (error) {
      dispatch(fetchProductsFailure(error.message));
      throw error;
    }
  };
};


export const fetchSectionProducts = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SECTION_PRODUCTS_REQUEST });
    try {
      const sections = await getSectionProducts();
      dispatch({ type: FETCH_SECTION_PRODUCTS_SUCCESS, payload: sections });
    } catch (error) {
      dispatch({ type: FETCH_SECTION_PRODUCTS_FAILURE, payload: error.message });
    }
  };
};

export const fetchProductsByCategory = (categoryId, subcategoryId, filters) => {
  return async (dispatch) => {
    dispatch(fetchProductsByCategoryRequest());
    try {
      const products = await getProductsByCategory(categoryId, subcategoryId, filters);
      dispatch(fetchProductsByCategorySuccess(products));
    } catch (error) {
      dispatch(fetchProductsByCategoryFailure(error.message));
    }
  };
};
