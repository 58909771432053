import {
  addToCartAPI,
  viewCartAPI,
  updateCartAPI,
  removeFromCartAPI,
} from '../../services/cart';
import { calculateCartTotalAction } from './appActions';

// Action Types
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE';
export const CLEAR_CART_ITEMS = 'CLEAR_CART_ITEMS';

// Add to Cart Actions
export const addToCartRequest = () => ({
  type: ADD_TO_CART_REQUEST,
});

export const addToCartSuccess = (cart) => ({
  type: ADD_TO_CART_SUCCESS,
  payload: cart,
});

export const addToCartFailure = (error) => ({
  type: ADD_TO_CART_FAILURE,
  payload: error,
});

// Fetch Cart Actions
export const fetchCartRequest = () => ({
  type: FETCH_CART_REQUEST,
});

export const fetchCartSuccess = (cart) => ({
  type: FETCH_CART_SUCCESS,
  payload: cart,
});

export const fetchCartFailure = (error) => ({
  type: FETCH_CART_FAILURE,
  payload: error,
});

// Update Cart Actions
export const updateCartRequest = () => ({
  type: UPDATE_CART_REQUEST,
});

export const updateCartSuccess = (cart) => ({
  type: UPDATE_CART_SUCCESS,
  payload: cart,
});

export const updateCartFailure = (error) => ({
  type: UPDATE_CART_FAILURE,
  payload: error,
});

// Remove from Cart Actions
export const removeFromCartRequest = () => ({
  type: REMOVE_FROM_CART_REQUEST,
});

export const removeFromCartSuccess = (cart) => ({
  type: REMOVE_FROM_CART_SUCCESS,
  payload: cart,
});

export const removeFromCartFailure = (error) => ({
  type: REMOVE_FROM_CART_FAILURE,
  payload: error,
});

export const clearCartItems = () => ({
  type: CLEAR_CART_ITEMS,
});

// Thunks
export const addToCartAction = (productId, quantity, attributes = [], addNewItem = true, cartId = null) => {
  return async (dispatch, getState) => {
    dispatch(addToCartRequest());
    try {
      await addToCartAPI(productId, quantity, attributes, addNewItem, cartId);
      const cart = await viewCartAPI(cartId);
      dispatch(addToCartSuccess(cart));
      const { auth } = getState();
      dispatch(calculateCartTotalAction(cart.currency, auth.cookieId));
    } catch (error) {
      dispatch(addToCartFailure(error.message));
    }
  };
};
export const fetchCart = (pagination = { page: 1, per_page: 10 }) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CART_REQUEST });
    try {
      const data = await viewCartAPI(pagination.page, pagination.per_page);
      dispatch({
        type: FETCH_CART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CART_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateCartAction = (cartId, productId, quantity, attributes, page = 1, perPage = 10) => {
  return async (dispatch, getState) => {
    dispatch(updateCartRequest());
    try {
      await updateCartAPI(productId, quantity, attributes);
      const cart = await viewCartAPI(page, perPage); // Pass page and perPage
      dispatch(updateCartSuccess(cart));
      const { auth } = getState();
      dispatch(calculateCartTotalAction(cart.currency, auth.cookieId));
    } catch (error) {
      dispatch(updateCartFailure(error.message));
    }
  };
};

export const removeFromCartAction = (cartId, productId, page = 1, perPage = 10) => {
  return async (dispatch, getState) => {
    dispatch(removeFromCartRequest());
    try {
      await removeFromCartAPI(productId);
      const cart = await viewCartAPI(page, perPage); 
      dispatch(removeFromCartSuccess(cart));
      const { auth } = getState();
      dispatch(calculateCartTotalAction(cart.currency, auth.cookieId));
    } catch (error) {
      dispatch(removeFromCartFailure(error.message));
    }
  };
};
