import {
    fetchNotifications,
    fetchNotificationById,
    deleteNotification,
    deleteAllNotifications,
    getUserNotificationsCount,
    getUserNotifications,
    markNotificationAsRead
  } from '../../services/notificationsService';
  
  export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
  export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
  export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
  
  export const FETCH_NOTIFICATION_BY_ID_REQUEST = 'FETCH_NOTIFICATION_BY_ID_REQUEST';
  export const FETCH_NOTIFICATION_BY_ID_SUCCESS = 'FETCH_NOTIFICATION_BY_ID_SUCCESS';
  export const FETCH_NOTIFICATION_BY_ID_FAILURE = 'FETCH_NOTIFICATION_BY_ID_FAILURE';
  
  export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
  export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
  export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
  
  export const DELETE_ALL_NOTIFICATIONS_REQUEST = 'DELETE_ALL_NOTIFICATIONS_REQUEST';
  export const DELETE_ALL_NOTIFICATIONS_SUCCESS = 'DELETE_ALL_NOTIFICATIONS_SUCCESS';
  export const DELETE_ALL_NOTIFICATIONS_FAILURE = 'DELETE_ALL_NOTIFICATIONS_FAILURE';
  
  export const GET_USER_NOTIFICATIONS_COUNT_REQUEST = 'GET_USER_NOTIFICATIONS_COUNT_REQUEST';
  export const GET_USER_NOTIFICATIONS_COUNT_SUCCESS = 'GET_USER_NOTIFICATIONS_COUNT_SUCCESS';
  export const GET_USER_NOTIFICATIONS_COUNT_FAILURE = 'GET_USER_NOTIFICATIONS_COUNT_FAILURE';
  
  export const GET_USER_NOTIFICATIONS_REQUEST = 'GET_USER_NOTIFICATIONS_REQUEST';
  export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
  export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
  
  export const MARK_NOTIFICATION_AS_READ_REQUEST = 'MARK_NOTIFICATION_AS_READ_REQUEST';
  export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
  export const MARK_NOTIFICATION_AS_READ_FAILURE = 'MARK_NOTIFICATION_AS_READ_FAILURE';
  
  export const fetchNotificationsAction = () => async (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });
    try {
      const notifications = await fetchNotifications();
      dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: notifications });
    } catch (error) {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE, payload: error.message });
    }
  };
  
  export const fetchNotificationByIdAction = (id) => async (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION_BY_ID_REQUEST });
    try {
      const notification = await fetchNotificationById(id);
      dispatch({ type: FETCH_NOTIFICATION_BY_ID_SUCCESS, payload: notification });
    } catch (error) {
      dispatch({ type: FETCH_NOTIFICATION_BY_ID_FAILURE, payload: error.message });
    }
  };
  
  export const deleteNotificationAction = (id) => async (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION_REQUEST });
    try {
      await deleteNotification(id);
      dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: DELETE_NOTIFICATION_FAILURE, payload: error.message });
    }
  };
  
  export const deleteAllNotificationsAction = () => async (dispatch) => {
    dispatch({ type: DELETE_ALL_NOTIFICATIONS_REQUEST });
    try {
      await deleteAllNotifications();
      dispatch({ type: DELETE_ALL_NOTIFICATIONS_SUCCESS });
    } catch (error) {
      dispatch({ type: DELETE_ALL_NOTIFICATIONS_FAILURE, payload: error.message });
    }
  };
  
  export const getUserNotificationsCountAction = () => async (dispatch) => {
    dispatch({ type: GET_USER_NOTIFICATIONS_COUNT_REQUEST });
    try {
      const count = await getUserNotificationsCount();
      dispatch({ type: GET_USER_NOTIFICATIONS_COUNT_SUCCESS, payload: count });
      return { payload: count };
    } catch (error) {
      dispatch({ type: GET_USER_NOTIFICATIONS_COUNT_FAILURE, payload: error.message });
      return { payload: null };
    }
  };
  
 
  export const getUserNotificationsAction = () => async (dispatch) => {
    dispatch({ type: GET_USER_NOTIFICATIONS_REQUEST });
    try {
      const notifications = await getUserNotifications();
      dispatch({ type: GET_USER_NOTIFICATIONS_SUCCESS, payload: notifications });
    } catch (error) {
      dispatch({ type: GET_USER_NOTIFICATIONS_FAILURE, payload: error.message });
    }
  };
  
  export const markNotificationAsReadAction = (id) => async (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_AS_READ_REQUEST });
    try {
      const updatedNotifications = await markNotificationAsRead(id);
      dispatch({ type: MARK_NOTIFICATION_AS_READ_SUCCESS, payload: updatedNotifications });
      return { payload: updatedNotifications };
    } catch (error) {
      dispatch({ type: MARK_NOTIFICATION_AS_READ_FAILURE, payload: error.message });
      return { payload: null };
    }
  };
  