import axiosInstance from '../utils/axiosInstance';
import { getLangAndCurrency } from '../utils/cartUtils';

export const getUserOrders = async (currency = 'SAR', page = 1, perPage = 10) => {
  const response = await axiosInstance.get('/orders', { params: { currency, page, per_page: perPage } });
  return response.data;
};

export const createOrder = async (userId, cartId, currency , address, description, discountCodeId) => {
  const response = await axiosInstance.post('/orders', {
    user_id: userId,
    cart_id: cartId,
    currency_code: currency,
    address,
    description,
    discount_code_id: discountCodeId,
  });
  return response.data;
};

export const viewOrder = async (orderId, currency = 'SAR') => {
  const response = await axiosInstance.get(`/orders/${orderId}`, { params: { currency } });
  return response.data;
};

export const deleteOrder = async (orderId) => {
  const response = await axiosInstance.delete(`/orders/${orderId}`);
  return response.data;
};

export const createOrderItem = async (orderItemData) => {
  const response = await axiosInstance.post('/orders/order-items', orderItemData);
  return response.data;
};

export const getOrderItem = async (orderItemId) => {
  const response = await axiosInstance.get(`/orders/${orderItemId}/order-items`);
  return response.data;
};

export const updateOrderItem = async (orderItemId, orderItemData) => {
  const response = await axiosInstance.put(`/orders/${orderItemId}/order-items`, orderItemData);
  return response.data;
};

export const deleteOrderItem = async (orderItemId) => {
  const response = await axiosInstance.delete(`/orders/${orderItemId}/order-items`);
  return response.data;
};

export const returnOrderService = async (orderId, reason) => {
  const response = await axiosInstance.post(`/orders/${orderId}/return`, { reason });
  return response.data;
};

export const fetchReturnOrderDetailsService = async (orderId) => {
  const response = await axiosInstance.get(`/orders/${orderId}/return-details`);
  return response.data;
};

export const applyDiscountCode = async (discountCode) => {
  const { currency } = getLangAndCurrency(); 
  const response = await axiosInstance.post('/apply/discount-code', {
    code: discountCode,
    currency, 
  });
  return response.data;
};
