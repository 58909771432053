const cache = {};

export const setCache = (key, data, ttl = 600000) => { 
  cache[key] = {
    data,
    expiry: Date.now() + ttl,
  };
};

export const getCache = (key) => {
  const cachedData = cache[key];
  if (!cachedData) return null;

  if (Date.now() > cachedData.expiry) {
    delete cache[key];
    return null;
  }

  return cachedData.data;
};
