import axiosInstance from '../utils/axiosInstance';
import { getLangAndCurrency, getCookieId } from '../utils/cartUtils';
import { getCache, setCache } from '../utils/cache';

export const getCurrencies = async () => {
  const cacheKey = 'currencies';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/currencies');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching currencies:', error);
    throw error;
  }
};

export const getSlides = async () => {
  const cacheKey = 'slides';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/slides');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching slides:', error);
    throw error;
  }
};

export const getSecondSlides = async () => {
  const cacheKey = 'secondSlides';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/second-slides');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching second slides:', error);
    throw error;
  }
};

export const getRandomAd = async () => {
  const cacheKey = 'randomAd';
  const cachedData = getCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await axiosInstance.get('/randomAd');
    setCache(cacheKey, response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching random ad:', error);
    throw error;
  }
};

export const testimonials = async () => {
  try {
    const response = await axiosInstance.get('/testimonials');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching random testimonials:', error);
    throw error;
  }
};

export const calculateCartTotal = async () => {
  try {
    const { currency } = getLangAndCurrency();
    const cookieId = getCookieId();
    const response = await axiosInstance.post('/cart/calculate-total', {
      cookie_id: cookieId,
      currency: currency,
    });
    return response.data;
  } catch (error) {
    console.error('Error calculating cart total:', error);
    throw error;
  }
};
