import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../../redux/actions/authActions';
import MetaTags from '../../components/MetaTags';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(login(identifier, password, navigate, toast)).finally(() => setIsLoading(false));
  };

  return (
    <>
      <MetaTags title={t('Login')} />
      <div className="flex min-h-screen bg-gray-400">
        <div
          className="hidden md:flex w-1/2 bg-cover bg-center"
          style={{ backgroundImage: "url('/group_Container_.png')" }}
        />
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 bg-gray-500 p-6">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-sm">
            <h2 className="text-2xl font-bold mb-4">{t('Login')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('emailOrUsernameOrWhatsapp')}</label>
                <input
                  type="text"
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('Password')}</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <button
  type="submit"
  className="w-1/2 mx-auto bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mb-4 flex items-center justify-center"
  disabled={isLoading}
>
  {isLoading ? (
    <div className="loader w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
  ) : (
    t('Login')
  )}
</button>

              <div className="text-center mt-4">
                <span className="text-gray-600">{t('No_account')}?</span>
                <Link to="/register" className="text-blue-500 hover:underline ml-2">
                  {t('Register')}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LoginPage;
