import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { activateAccount } from '../../redux/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from '../../components/MetaTags';

const ActivatePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state && location.state.username) {
      setUsername(location.state.username);
    } else {
      toast.warning(t('You need to log in to activate your account'));
      navigate('/login');
    }
  }, [location, navigate, t]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(activateAccount(username, verificationCode, navigate, t)).finally(() => setIsLoading(false));
  };

  return (
    <>
      <MetaTags title={t('Activate_Account')} />
      <div className="flex min-h-screen bg-gray-400">
        <div
          className="hidden md:flex w-1/2 bg-cover bg-center"
          style={{ backgroundImage: "url('/group_Container_.png')" }}
        />
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 bg-gray-500 p-6">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-sm">
            <h2 className="text-2xl font-bold mb-4">{t('Activate_Account')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('Username')}</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">{t('Verification_Code')}</label>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-1/2 mx-auto bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loader w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
                ) : (
                  t('Activate')
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ActivatePage;
