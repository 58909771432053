import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import {
  login as loginService,
  register as registerService,
  activateAccount as activateAccountService,
  socialLogin as socialLoginService,
  handleGoogleSuccess as handleGoogleSuccessService,
  handleFacebookResponse as handleFacebookResponseService,
  getUserProfile,
  updateUserProfileService,
  updateUserPasswordService,
  logoutService
} from '../../services/authService';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';



export const login = (identifier, password, navigate, toast) => async (dispatch) => {
  try {
    const response = await loginService(identifier, password);

    if (response.valid === 1) {
      const { token, user } = response;
      dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });
      
      const lastRoute = Cookies.get('lastRoute') || '/';
      window.location.href = lastRoute;
      toast.success(response.message);
    } else {
      toast.error(response.message);
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach(error => toast.error(error));
      }
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(err => toast.error(err));
    } else if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Error logging in. Please check your credentials.');
    }
    console.error('Error logging in:', error);
  }
};

export const register = (userData, navigate, toast) => async (dispatch) => {
  try {
    const response = await registerService(userData);
    if (response.valid === 1) {
      navigate('/activate', { state: { username: userData.username } });
      toast.success(response.message);
    } else {
      toast.error(response.message);
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach(error => toast.error(error));
      }
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(err => toast.error(err));
    } else if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Error registering. Please try again.');
    }
    console.error('Error registering:', error);
  }
};
export const activateAccount = (username, verificationCode, navigate, t) => async (dispatch) => {
  try {
    const response = await activateAccountService(username, verificationCode);
    if (response.valid === 1) {
     
      const { token, user } = response;
      dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });
    
      navigate(Cookies.get('lastRoute') || '/');
      toast.success(t('Your account has been activated successfully'));
    } else {
      toast.error(response.message);
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach(error => toast.error(error));
      }
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(err => toast.error(err));
    } else if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(t('There was an error activating your account'));
    }
    console.error('Error activating account:', error);
  }
};
export const socialLogin = (userData, navigate) => async (dispatch) => {
  try {
    const { token, user } = await socialLoginService(userData);
    if (token && user) {
      navigate(Cookies.get('lastRoute') || '/');
      dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });

    } else {
      throw new Error('Invalid social login response');
    }
  } catch (error) {
    console.error('Error with social login:', error);
  }
};

export const handleGoogleSuccess = (credential, navigate) => async (dispatch) => {
  try {
    const { token, user } = await handleGoogleSuccessService(credential);
    if (token && user) {
      dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });
      navigate(Cookies.get('lastRoute') || '/');
    } else {
      throw new Error('Invalid Google login response');
    }
  } catch (error) {
    console.error('Google authentication error:', error);
  }
};

export const handleFacebookResponse = (response, navigate) => async (dispatch) => {
  try {
    const { token, user } = await handleFacebookResponseService(response);
    if (token && user) {
      dispatch({ type: LOGIN_SUCCESS, payload: { token, user } });
      navigate(Cookies.get('lastRoute') || '/');
    } else {
      throw new Error('Invalid Facebook login response');
    }
  } catch (error) {
    console.error('Facebook authentication error:', error);
  }
};

export const logout = (navigate) => async (dispatch) => {
  try {
    const response = await logoutService();
    if (response.valid === 1) {
      Cookies.remove('authToken');
      Cookies.remove('authUser');
      localStorage.removeItem('cookie_id');
      dispatch({ type: LOGOUT });
      window.location.href = '/login';
      toast.success(response.message);
    } else {
      window.location.href = '/login';
      toast.error(response.message);
    }
  } catch (error) {
    toast.error('Error logging out. Please try again.');
    console.error('Error logging out:', error);
  }
};


// Fetch User Profile
export const fetchUserProfile = () => async (dispatch) => {
  try {
    const profileData = await getUserProfile();
    if (profileData.valid === 1) {
      dispatch({ type: FETCH_USER_PROFILE, payload: profileData });
      Cookies.set('authUser', JSON.stringify(profileData.user), { expires: 30 });
      // toast.success(profileData.message);
    } else {
      toast.error(profileData.message);
    }
  } catch (error) {
    toast.error('Failed to fetch user profile');
    console.error('Error fetching user profile:', error);
  }
};
// Update User Profile
export const updateUserProfile = (updatedData) => async (dispatch) => {
  try {
    const response = await updateUserProfileService(updatedData);
    if (response.valid === 1) {
      dispatch({ type: UPDATE_USER_PROFILE, payload: response.user });
      toast.success(response.message);
      dispatch(fetchUserProfile()); // Fetch user profile after update
    } else {
      toast.error(response.message);
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach(error => toast.error(error));
      }
    }
  } catch (error) {
    toast.error('Error updating profile. Please try again.');
    console.error('Error updating user profile:', error);
  }
};

// Update User Password
export const updateUserPassword = (passwordData) => async (dispatch) => {
  try {
    const response = await updateUserPasswordService(passwordData);
    if (response.valid === 1) {
      toast.success(response.message);
      dispatch(fetchUserProfile()); // Fetch user profile after updating password
    } else {
      toast.error(response.message);
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach(error => toast.error(error));
      }
    }
  } catch (error) {
    toast.error('Error updating password. Please try again.');
    console.error('Error updating user password:', error);
  }
};