// reducers/adsReducer.js
import {
    FETCH_RANDOM_AD_REQUEST,
    FETCH_RANDOM_AD_SUCCESS,
    FETCH_RANDOM_AD_FAILURE,
    FETCH_ALL_ADS_REQUEST,
    FETCH_ALL_ADS_SUCCESS,
    FETCH_ALL_ADS_FAILURE,
  } from '../actions/adsActions';
  
  const initialState = {
    randomAd: null,
    allAds: [],
    loading: false,
    error: null,
  };
  
  const adsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RANDOM_AD_REQUEST:
      case FETCH_ALL_ADS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_RANDOM_AD_SUCCESS:
        return { ...state, loading: false, randomAd: action.payload };
      case FETCH_ALL_ADS_SUCCESS:
        return { ...state, loading: false, allAds: action.payload };
      case FETCH_RANDOM_AD_FAILURE:
      case FETCH_ALL_ADS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default adsReducer;
  