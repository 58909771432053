import axiosInstance from '../utils/axiosInstance';
import { getLangAndCurrency, getCookieId, setCookieId } from '../utils/cartUtils';


export const addToCartAPI = async (productId, quantity, attributes = []) => {
  const { currency } = getLangAndCurrency();
  const cookieId = getCookieId();
  const response = await axiosInstance.post('/cart/add', {
    product_id: productId,
    quantity: quantity,
    attributes: attributes,
    cookie_id: cookieId,
    currency: currency,
  });


  if (response.data.data && response.data.data.original && response.data.data.original.cart && response.data.data.original.cart.cookie_id) {
    const newCookieId = response.data.data.original.cart.cookie_id;
    setCookieId(newCookieId);
  } else {
    console.log("Cookie ID not found in response.");
  }

  return response.data;
};

export const viewCartAPI = async (page = 1, perPage = 10) => {
  const { currency } = getLangAndCurrency();
  const cookieId = getCookieId();
  
  const response = await axiosInstance.get('/cart', {
    params: {
      cookie_id: cookieId,
      currency: currency,
      page: page,
      per_page: perPage,
    },
  });

  if (response.data.cart && response.data.cart.cookie_id) {
    setCookieId(response.data.cart.cookie_id);
  }

  return response.data;
};


export const updateCartAPI = async (productId, quantity, attributes) => {
  const { currency } = getLangAndCurrency();
  const cookieId = getCookieId();
  const response = await axiosInstance.post('/cart/update', {
    cookie_id: cookieId,
    product_id: productId,
    quantity: quantity,
    attributes: attributes,
    currency: currency,
  });

  // Save cookie_id if present in the response
  if (response.data.cart && response.data.cart.cookie_id) {
    setCookieId(response.data.cart.cookie_id);
  }

  return response.data;
};

export const removeFromCartAPI = async (productId) => {
  const { currency } = getLangAndCurrency();
  const cookieId = getCookieId();
  const response = await axiosInstance.post('/cart/remove', {
    cookie_id: cookieId,
    product_id: productId,
    currency: currency,
  });

  // Save cookie_id if present in the response
  if (response.data.cart && response.data.cart.cookie_id) {
    setCookieId(response.data.cart.cookie_id);
  }

  return response.data;
};
