import axiosInstance from '../utils/axiosInstance';

export const createRating = async (product_id, rating, review) => {
  try {
    const response = await axiosInstance.post('/ratings', {
      product_id,
      rating,
      review,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
