import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../../redux/actions/productActions';
import { fetchCategories, fetchCategoryById } from '../../redux/actions/categoryActions';
import { useTranslation } from 'react-i18next';
import EcommerceCard from '../../components/EcommerceCard';
import Pagination from '../../components/Pagination';
import MetaTags from '../../components/MetaTags';
import { useLocation } from 'react-router-dom';

const ProductPage = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products) || [];
  const categories = useSelector((state) => state.category.categories) || [];
  const selectedCategoryDetails = useSelector((state) => state.category.category) || {};
  const loading = useSelector((state) => state.products.loading);
  const error = useSelector((state) => state.products.error);
  const total = useSelector((state) => state.products.total);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [showFilters, setShowFilters] = useState(false);
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSubsubcategory, setSelectedSubsubcategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const filterRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');
    const sectionQuery = searchParams.get('section');

    if (searchQuery) {
      setNameFilter(searchQuery);
    }

    const fetchData = async () => {
      const response = await dispatch(fetchProducts({
        priceFrom,
        priceTo,
        nameFilter,
        section: sectionQuery,
        selectedCategory,
        selectedSubcategory,
        selectedSubsubcategory,
        page: currentPage,
        limit: itemsPerPage,
      }));
      if (response && response.total) {
        setTotalPages(Math.ceil(response.total / itemsPerPage));
      }
    };

    fetchData();
    dispatch(fetchCategories());
  }, [dispatch, location.search, priceFrom, priceTo, nameFilter, selectedCategory, selectedSubcategory, selectedSubsubcategory, currentPage, itemsPerPage]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(fetchCategoryById(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  const handleFilter = () => {
    setCurrentPage(1);
    dispatch(fetchProducts({
      priceFrom,
      priceTo,
      nameFilter,
      section: null,
      selectedCategory,
      selectedSubcategory,
      selectedSubsubcategory,
      page: 1,
      limit: itemsPerPage,
    }));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      const searchParams = new URLSearchParams(location.search);
      const sectionQuery = searchParams.get('section');
      dispatch(fetchProducts({
        priceFrom,
        priceTo,
        nameFilter,
        section: sectionQuery,
        selectedCategory,
        selectedSubcategory,
        selectedSubsubcategory,
        page: newPage,
        limit: itemsPerPage,
      }));
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 mx-1 rounded ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
    dispatch(fetchProducts({
      priceFrom,
      priceTo,
      nameFilter,
      section: null,
      selectedCategory,
      selectedSubcategory,
      selectedSubsubcategory,
      page: 1,
      limit: parseInt(e.target.value),
    }));
  };

  return (
    <>
      <MetaTags title={t('Products')} />
      <div className="mx-auto mt-4 flex flex-col">
       
        <div className="md:hidden w-full flex justify-center items-center mb-4">
       

          <button
            className={`bg-blue-500 text-white p-2 rounded mx-2`}
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>
        {showFilters && (
          <div className="md:hidden p-4 border mb-4">
            <div className="mb-2 w-full px-4">
                <label>{i18n.t('priceFrom')}: </label>
                <input
                  type="number"
                  value={priceFrom}
                  onChange={(e) => setPriceFrom(e.target.value)}
                  className="border p-1 w-full"
                />
              </div>
              
              <div className="mb-2 w-full px-4">
                
                <label>{i18n.t('priceTo')}: </label>
                <input
                  type="number"
                  value={priceTo}
                  onChange={(e) => setPriceTo(e.target.value)}
                  className="border p-1 w-full"
                />
              </div>
              <div className="mb-2 w-full px-4">
              <label>{i18n.t('name')}: </label>
              <input
                type="text"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                  className="border p-1 w-full"
              />
            </div>
           
            <div className="mb-2 w-full px-4">
              <label>{i18n.t('categories')}: </label>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                  className="border p-1 w-full"
              >
                <option value="">{i18n.t('selectCategory')}</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {i18n.language === 'ar' ? cat.ar_name : cat.en_name}
                  </option>
                ))}
              </select>
            </div>
            {selectedCategory && selectedCategoryDetails.subcategories && (
              <div className="mb-2 w-full px-4">
              <label>{i18n.t('Subcategory')}: </label>
              <select
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                className="border p-1 w-full"
              >
                  <option value="">{i18n.t('selectSubcategory')}</option>
                  {selectedCategoryDetails.subcategories.map((subcat) => (
                    <option key={subcat.id} value={subcat.id}>
                      {i18n.language === 'ar' ? subcat.ar_name : subcat.en_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {selectedSubcategory && selectedCategoryDetails.subcategories && selectedCategoryDetails.subcategories.find(sub => sub.id === selectedSubcategory)?.children && (
             <div className="mb-2 w-full px-4">
             <label>{i18n.t('subsubcategory')}: </label>
             <select
               value={selectedSubsubcategory}
               onChange={(e) => setSelectedSubsubcategory(e.target.value)}
               className="border p-1 w-full"
             >
                  <option value="">{i18n.t('selectSubsubcategory')}</option>
                  {selectedSubsubcategory.subcategories.find(sub => sub.id === selectedSubcategory)?.children.map((child) => (
                    <option key={child.id} value={child.id}>
                      {i18n.language === 'ar' ? child.ar_name : child.en_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}
        <div className={`flex ${i18n.dir() === 'rtl' ? 'flex-row-reverse' : 'flex-row'}`}>
       
          <div ref={filterRef} className={`w-1/6 p-2 border-r hidden md:flex flex-col items-center ${i18n.dir() === 'rtl' ? 'order-last' : 'order-first'}`}>
            <div className="flex-1 overflow-y-auto max-h-[calc(100vh-100px)] w-full flex flex-col justify-center items-center">
              <h2 className="mb-4">{i18n.t('filters')}</h2>
              <div className="mb-2 w-full px-4">
                <label>{i18n.t('priceFrom')}: </label>
                <input
                  type="number"
                  value={priceFrom}
                  onChange={(e) => setPriceFrom(e.target.value)}
                  className="border p-1 w-full"
                />
              </div>
              
              <div className="mb-2 w-full px-4">
                
                <label>{i18n.t('priceTo')}: </label>
                <input
                  type="number"
                  value={priceTo}
                  onChange={(e) => setPriceTo(e.target.value)}
                  className="border p-1 w-full"
                />
              </div>
              <div className="mb-2 w-full px-4">
                <label>{i18n.t('name')}: </label>
                <input
                  type="text"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                  className="border p-1 w-full"
                />
              </div>
             
              <div className="mb-2 w-full px-4">
                <label>{i18n.t('categories')}: </label>
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="border p-1 w-full"
                >
                  <option value="">{i18n.t('SelectCategory')}</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {i18n.language === 'ar' ? cat.ar_name : cat.en_name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedCategory && selectedCategoryDetails.subcategories && (
                <div className="mb-2 w-full px-4">
                  <label>{i18n.t('Subcategory')}: </label>
                  <select
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    className="border p-1 w-full"
                  >
                    <option value="">{i18n.t('SelectSubcategory')}</option>
                    {selectedCategoryDetails.subcategories.map((subcat) => (
                      <option key={subcat.id} value={subcat.id}>
                        {i18n.language === 'ar' ? subcat.ar_name : subcat.en_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {selectedSubcategory && selectedCategoryDetails.subcategories && selectedCategoryDetails.subcategories.find(sub => sub.id === selectedSubcategory)?.children && (
                <div className="mb-2 w-full px-4">
                  <label>{i18n.t('subsubcategory')}: </label>
                  <select
                    value={selectedSubsubcategory}
                    onChange={(e) => setSelectedSubsubcategory(e.target.value)}
                    className="border p-1 w-full"
                  >
                    <option value="">{i18n.t('selectSubsubcategory')}</option>
                    {selectedCategoryDetails.subcategories.find(sub => sub.id === selectedSubcategory)?.children.map((child) => (
                      <option key={child.id} value={child.id}>
                        {i18n.language === 'ar' ? child.ar_name : child.en_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <button
                className="bg-green-500 text-white p-2 rounded mt-4 w-4/5"
                onClick={handleFilter}
              >
                {i18n.t('applyFilters')}
              </button>
            </div>
          </div>
          <div className="w-full md:w-5/6 p-4">
          <h1 className='text-center mb-4'>{i18n.t('Products')}</h1>
            <div>
              {loading && <p>{i18n.t('loading')}</p>}
              {products.length === 0 ? (
                <div className="text-center text-red-500">{i18n.t('noProductsFound')}</div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">

                  {products.map((product) => (
                    <div key={product.id} className="flex justify-center w-full">
                      <EcommerceCard
                        productId={product.id}
                        images={product.images}
                        title={i18n.language === 'ar' ? product.ar_title : product.en_title}
                        description={i18n.language === 'ar' ? product.ar_description : product.en_description}
                        price={parseFloat(product.price)}
                        discount={product.discount ? product.discount : 0}
                        discountType={product.discount_type}
                        discountedPrice={parseFloat(product.discounted_price)}
                        tagText={product.tag_text}
                        isNew={product.is_new}
                        tagColor={product.tag_color}
                        currency={product.currency}
                        favorite={product.favorite}
                        stock={product.stock}
                        className="w-full"
                        cart={product.cart}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
           
          </div>
        </div>
        <div className="flex justify-center mt-4">
            <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
      />
    </div>
      </div>
    </>
  );
};

export default ProductPage;
