import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_SECTION_PRODUCTS_REQUEST,
  FETCH_SECTION_PRODUCTS_SUCCESS,
  FETCH_SECTION_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_BY_CATEGORY_REQUEST,
  FETCH_PRODUCTS_BY_CATEGORY_SUCCESS,
  FETCH_PRODUCTS_BY_CATEGORY_FAILURE,
} from '../actions/productActions';

const initialState = {
  products: [],
  sections: [],
  categoryProducts: [],
  total: 0,
  loading: false,
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_SECTION_PRODUCTS_REQUEST:
    case FETCH_PRODUCTS_BY_CATEGORY_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.payload.products, total: action.payload.total };
    case FETCH_SECTION_PRODUCTS_SUCCESS:
      return { ...state, loading: false, sections: action.payload };
    case FETCH_PRODUCTS_BY_CATEGORY_SUCCESS:
      return { ...state, loading: false, categoryProducts: Array.isArray(action.payload) ? action.payload : [] };
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_SECTION_PRODUCTS_FAILURE:
    case FETCH_PRODUCTS_BY_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default productReducer;
