import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserOrders, removeOrder, returnOrder, fetchReturnOrderDetails } from '../../redux/actions/orderActions';
import { useTranslation } from 'react-i18next';
import MetaTags from '../../components/MetaTags';
import Pagination from '../../components/Pagination';
import moment from 'moment';
import { getFullImageUrl, defaultAvatar } from "../../utils/imageUtils";
import './OrderPage.css';

const statusNames = {
  en: {
    pending: 'Pending',
    completed: 'Completed',
    cancelled: 'Cancelled',
    return_product: 'Return Product',
  },
  ar: {
    pending: 'قيد الانتظار',
    completed: 'مكتمل',
    cancelled: 'ملغى',
    return_product: 'منتج مرتجع',
  },
};

const OrdersPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders || { pending: [], completed: [], cancelled: [], returnProduct: [] });
  const loading = useSelector((state) => state.orders.loading);
  const error = useSelector((state) => state.orders.error);
  const currentPage = useSelector((state) => state.orders.currentPage);
  const totalPages = useSelector((state) => state.orders.lastPage);
  const returnOrderDetails = useSelector((state) => state.orders.returnOrderDetails);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState('all');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [returnModalIsOpen, setReturnModalIsOpen] = useState(false);
  const [returnReason, setReturnReason] = useState('');

  useEffect(() => {
    const currency = localStorage.getItem('selectedCurrency') || 'SAR';
    dispatch(fetchUserOrders(currency, currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setModalIsOpen(true);
  };

  const handleDeleteOrder = (orderId) => {
    const currency = localStorage.getItem('selectedCurrency') || 'SAR';
    dispatch(removeOrder(orderId)).then(() => {
      dispatch(fetchUserOrders(currency, currentPage, itemsPerPage));
    });
  };

  const handlePageChange = (page) => {
    dispatch(fetchUserOrders(localStorage.getItem('selectedCurrency') || 'SAR', page, itemsPerPage));
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    dispatch(fetchUserOrders(localStorage.getItem('selectedCurrency') || 'SAR', 1, parseInt(event.target.value)));
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const filteredOrders = () => {
    if (filterStatus === 'all') {
      return [...orders.pending, ...orders.completed, ...orders.cancelled, ...orders.returnProduct];
    }
    return orders[filterStatus];
  };

  const isOrderReturnable = (createdAt) => {
    const orderDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - orderDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays <= 14;
  };

  const handleReturnOrder = (order) => {
    setSelectedOrder(order);
    setReturnModalIsOpen(true);
    dispatch(fetchReturnOrderDetails(order.id)); // Fetch return order details
  };

  const handleReturnSubmit = () => {
    dispatch(returnOrder(selectedOrder.id, returnReason));
    setReturnModalIsOpen(false);
  };

  const renderTable = () => (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="py-2">{t('orders.orderId')}</th>
          <th className="py-2">{t('orders.status')}</th>
          <th className="py-2">{t('orders.totalPrice')}</th>
          <th className="py-2">{t('orders.address')}</th>
          <th className="py-2">{t('orders.description')}</th>
          <th className="py-2">{t('orders.hasDiscountCode')}</th>
          <th className="py-2">{t('orders.items')}</th>
          <th className="py-2">{t('orders.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {filteredOrders().map((order) => (
          <tr key={order.id} className="border-t">
            <td className="py-2">{order.id}</td>
            <td className="py-2">{statusNames[i18n.language][order.status]}</td>
            <td className="py-2">{order.total_price} {order.currency}</td>
            <td className="py-2">{order.address}</td>
            <td className="py-2">{order.description}</td>
            <td className="py-2">{order.has_discount_code ? t('yes') : t('no')}</td>
            <td className="py-2">
              <ul>
                {order.items.map((item) => (
                  <li key={item.id}>
                    <span>{i18n.language === 'ar' ? item.product?.ar_title : item.product?.en_title || t('orders.unknown_product')}</span> - 
                    <span>{item.quantity}</span> - 
                    <span>{item.price} {order.currency}</span>
                  </li>
                ))}
              </ul>
            </td>
            <td className="py-2">
              <button
                onClick={() => handleViewOrder(order)}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                {t('orders.view')}
              </button>
              {order.status === 'cancelled' && (
                <button
                  onClick={() => handleDeleteOrder(order.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  {t('orders.delete')}
                </button>
              )}
              {order.status !== 'cancelled' && order.status !== 'return_product' && isOrderReturnable(order.created_at) && (
                <button
                  onClick={() => handleReturnOrder(order)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded"
                >
                  {t('orders.returnProduct')}
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderModal = () => (
    <div className={`popup ${modalIsOpen ? 'open' : ''}`}>
      <div className="popup-inner">
        <button className="close-btn" onClick={() => setModalIsOpen(false)}>&times;</button>
        {selectedOrder && (
          <div className="p-4">
            <h2 className="text-2xl mb-4">{t('orders.order_details')}</h2>
            <p><strong>{t('orders.orderId')}:</strong> {selectedOrder.id}</p>
            <p><strong>{t('orders.status')}:</strong> {statusNames[i18n.language][selectedOrder.status]}</p>
            <p><strong>{t('orders.totalPrice')}:</strong> {selectedOrder.total_price} {selectedOrder.currency}</p>
            <p><strong>{t('orders.address')}:</strong> {selectedOrder.address}</p>
            <p><strong>{t('orders.description')}:</strong> {selectedOrder.description}</p>
            <p><strong>{t('orders.hasDiscountCode')}:</strong> {selectedOrder.has_discount_code ? t('yes') : t('no')}</p>
            <h3 className="text-xl mt-4">{t('orders.items')}</h3>
            <ul>
              {selectedOrder.items.map((item) => (
                <li key={item.id} className="mb-2">
                  <span>{i18n.language === 'ar' ? item.product?.ar_title : item.product?.en_title || t('orders.unknown_product')}</span> - 
                  <span>{item.quantity}</span> - 
                  <span>{item.price} {selectedOrder.currency}</span>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setModalIsOpen(false)}
              className="bg-red-500 text-white px-4 py-2 rounded mt-4"
            >
              {t('close')}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const renderReturnModal = () => (
    <div className={`popup ${returnModalIsOpen ? 'open' : ''}`}>
      <div className="popup-inner">
        <button className="close-btn" onClick={() => setReturnModalIsOpen(false)}>&times;</button>
        {selectedOrder && (
          <div className="p-4">
            <h2 className="text-2xl mb-4">{t('orders.returnProduct')}</h2>
           
            <div className="chat-messages mt-4">
              {Array.isArray(returnOrderDetails.data) && returnOrderDetails.data.map((message, index) => (
                <div key={index} className={`chat-message row col-12 ${message.user_type === 'client' ? '' : 'flex-row-reverse'}`} style={{ display: 'flex', marginBottom: '10px' }}>
                  <div className="col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div className="chat-avatar" style={{ marginRight: '10px' }}>
                      <img  src={
                      message.avatar
                        ? getFullImageUrl(message.avatar)
                        : defaultAvatar
                    }
                    alt={message.username} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                    </div>
                    <div className="chat-text" style={{ flexGrow: 1 }}>
                      <div className="chat-username" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                        {message.username}
                      </div>
                      <div className="chat-message-text" style={{ marginBottom: '5px' }}>
                        {message.text}
                      </div>
                      <div className="chat-time" style={{ fontSize: '0.85em', color: '#777' }}>
                        {moment(message.created_at).format('LLL')}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <textarea
              className="w-full p-2 border rounded"
              value={returnReason}
              onChange={(e) => setReturnReason(e.target.value)}
              placeholder={t('orders.enterReturnReason')}
            />
            <button
              onClick={handleReturnSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
            >
              {t('orders.submit')}
            </button>
            <button
              onClick={() => setReturnModalIsOpen(false)}
              className="bg-red-500 text-white px-4 py-2 rounded mt-4 ml-2"
            >
              {t('close')}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  if (loading) return <p>{t('loading')}</p>;
  // if (error) return <p>{t('error')}: {error.message}</p>;

  return (
    <>
      <MetaTags title={t('orders.title')} />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-4">{t('orders.title')}</h1>
        
        <div className="mb-4">
          <label htmlFor="statusFilter" className="mr-2">{t('orders.filterByStatus')}</label>
          <select
            id="statusFilter"
            value={filterStatus}
            onChange={handleFilterChange}
            className="p-2 border rounded"
          >
            <option value="all">{t('orders.all')}</option>
            <option value="pending">{statusNames[i18n.language].pending}</option>
            <option value="completed">{statusNames[i18n.language].completed}</option>
            <option value="cancelled">{statusNames[i18n.language].cancelled}</option>
            <option value="returnProduct">{statusNames[i18n.language].return_product}</option>
          </select>
        </div>
        
        <div className="overflow-x-auto">
          {renderTable()}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
      {renderModal()}
      {renderReturnModal()}
    </>
  );
};

export default OrdersPage;
