import { getFavorites, addToFavorites, removeFromFavorites } from '../../services/favoritesApi';

export const FETCH_FAVORITES_REQUEST = 'FETCH_FAVORITES_REQUEST';
export const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS';
export const FETCH_FAVORITES_FAILURE = 'FETCH_FAVORITES_FAILURE';

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';

export const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
export const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
export const REMOVE_FAVORITE_FAILURE = 'REMOVE_FAVORITE_FAILURE';

export const fetchFavorites = () => async (dispatch) => {
  dispatch({ type: FETCH_FAVORITES_REQUEST });
  try {
    const { data } = await getFavorites();
    dispatch({ type: FETCH_FAVORITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_FAVORITES_FAILURE, payload: error.message });
  }
};

export const addFavorite = (productId) => async (dispatch) => {
  dispatch({ type: ADD_FAVORITE_REQUEST });
  try {
    const newFavorite = await addToFavorites(productId);
    dispatch({ type: ADD_FAVORITE_SUCCESS, payload: newFavorite });
  } catch (error) {
    dispatch({ type: ADD_FAVORITE_FAILURE, payload: error.message });
  }
};

export const removeFavorite = (productId) => async (dispatch) => {
  dispatch({ type: REMOVE_FAVORITE_REQUEST });
  try {
    await removeFromFavorites(productId);
    dispatch({ type: REMOVE_FAVORITE_SUCCESS, payload: productId });
  } catch (error) {
    dispatch({ type: REMOVE_FAVORITE_FAILURE, payload: error.message });
  }
};
  