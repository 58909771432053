// actions/adsActions.js
import { getRandomAd, getAllAds } from '../../services/ads';

export const FETCH_RANDOM_AD_REQUEST = 'FETCH_RANDOM_AD_REQUEST';
export const FETCH_RANDOM_AD_SUCCESS = 'FETCH_RANDOM_AD_SUCCESS';
export const FETCH_RANDOM_AD_FAILURE = 'FETCH_RANDOM_AD_FAILURE';

export const FETCH_ALL_ADS_REQUEST = 'FETCH_ALL_ADS_REQUEST';
export const FETCH_ALL_ADS_SUCCESS = 'FETCH_ALL_ADS_SUCCESS';
export const FETCH_ALL_ADS_FAILURE = 'FETCH_ALL_ADS_FAILURE';

export const fetchRandomAd = () => async (dispatch) => {
  dispatch({ type: FETCH_RANDOM_AD_REQUEST });
  try {
    const ad = await getRandomAd();
    dispatch({ type: FETCH_RANDOM_AD_SUCCESS, payload: ad });
  } catch (error) {
    dispatch({ type: FETCH_RANDOM_AD_FAILURE, payload: error.message });
  }
};

export const fetchAllAds = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_ADS_REQUEST });
  try {
    const ads = await getAllAds();
    dispatch({ type: FETCH_ALL_ADS_SUCCESS, payload: ads });
  } catch (error) {
    dispatch({ type: FETCH_ALL_ADS_FAILURE, payload: error.message });
  }
};
