import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import { DirectionProvider } from './context/DirectionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <DirectionProvider>
          <App />
        </DirectionProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
