import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faHeart,
  faUser,
  faSearch,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import LanguageSwitcher from "./LanguageSwitcher";
import { useDirection } from "../context/DirectionContext";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/actions/authActions";
import { calculateCartTotalAction } from "../redux/actions/appActions";
import Currencies from "./Currencies";
import { defaultAvatar, getFullImageUrl } from "../utils/imageUtils";
import debounce from "lodash.debounce";
import useScrollDirection from "../hooks/useScrollDirection";
import Notifications from "./Notifications";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/Navbar.css";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpenMobile, setIsDropdownOpenMobile] = useState(false);
  const [isDropdownOpenDesktop, setIsDropdownOpenDesktop] = useState(false);
  const [ setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { direction } = useDirection();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth);
  const cartTotal = useSelector((state) => state.app.cartTotal);
  const scrollDirection = useScrollDirection();

  const dropdownRefMobile = useRef(null);
  const dropdownRefDesktop = useRef(null);

  const handleLogout = () => {

    dispatch(logout(navigate));
    handleLinkClick();
  };

  const handleCurrencyChange = (newCurrency) => {
    // Handle currency change if needed
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdownMobile = () => {
    setIsDropdownOpenMobile(!isDropdownOpenMobile);
  };

  const toggleDropdownDesktop = () => {
    setIsDropdownOpenDesktop(!isDropdownOpenDesktop);
  };

  const handleClickOutside = (event) => {
    if (dropdownRefMobile.current && !dropdownRefMobile.current.contains(event.target)) {
      setIsDropdownOpenMobile(false);
    }
    if (dropdownRefDesktop.current && !dropdownRefDesktop.current.contains(event.target)) {
      setIsDropdownOpenDesktop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    navigate(`/products?search=${e.target.value}`);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  useEffect(() => {
    return () => {
      debouncedHandleSearchChange.cancel();
    };
  }, []);

  useEffect(() => {
    if (user.token) {
      dispatch(calculateCartTotalAction("SAR", user.cookieId));
    }
  }, [dispatch, user.token, user.cookieId]);

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
    setIsDropdownOpenMobile(false);
    setIsDropdownOpenDesktop(false);
  };

  return (
    <>
      <nav
        className={`bg-white py-4 shadow-md fixed-top w-full z-50 transition-transform duration-300 ${
          scrollDirection === "down" ? "-translate-y-full" : "translate-y-0"
        }`}
      >
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="text-lg font-bold text-gray-700">
            <Link to="/" onClick={handleLinkClick}>
              <img src="/logo.png" alt="Logo" className="h-10" />
            </Link>
          </div>
          <div className="relative flex items-center flex-grow mx-4 hidden md:flex">
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 text-gray-400 text-2xl"
            />
            <input
              type="text"
              className="w-full py-2 pl-10 pr-4 border rounded-md"
              placeholder={t("Search_placeholder")}
              onChange={debouncedHandleSearchChange}
            />
          </div>
          <div className="flex items-center flex-grow mx-4 hidden md:flex space-x-4">
            <Link to="/" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Home")}
            </Link>
            <Link to="/products" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Products")}
            </Link>
            <Link to="/orders" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Orders")}
            </Link>
            <Link to="/cart" className="text-lg font-bold text-gray-700 relative" onClick={handleLinkClick}>
              <div className="relative inline-block">
                <FontAwesomeIcon icon={faShoppingCart} className="text-2xl" />
                {cartTotal.total_quantity > 0 && (
                  <span className="absolute top-[-0.5rem] right-[-0.5rem] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                    {cartTotal.total_quantity}
                  </span>
                )}
              </div>
              <span className="ml-2">
                {cartTotal.currency} {cartTotal.total_price}
              </span>
            </Link>
            <Link to="/favorites" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faHeart} className="text-2xl" />
            </Link>
            <div className="text-lg font-bold text-gray-700 cursor-pointer">
              <Currencies onCurrencyChange={handleCurrencyChange} />
            </div>
            <LanguageSwitcher />
            {user.token && user.user && <Notifications />}
            <div className="relative " ref={dropdownRefDesktop}>
              <button
                onClick={toggleDropdownDesktop}
                className="text-lg font-bold text-gray-700 flex items-center space-x-2 focus:outline-none"
              >
                {user.token && user.user ? (
                  <>
                    {user.user.avatar ? (
                      <img
                        src={user.user.avatar ? getFullImageUrl(user.user.avatar) : defaultAvatar}  
                        alt="Avatar"
                        className="w-10 h-10 rounded-full"
                      />
                    ) : (
                      <span>{user.user.fullName}</span>
                    )}
                  </>
                ) : (
                  <FontAwesomeIcon icon={faUser} className="text-2xl" />
                )}
              </button>
              {isDropdownOpenDesktop && (
                <div
                  className={`absolute ${direction === "rtl" ? "left-0" : "right-0"} w-48 mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-50`}
                >
                  {user.token && user.user ? (
                    <>
                      <Link to="/profile" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Profile")}
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                      >
                        {t("Logout")}
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to="/login" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Login")}
                      </Link>
                      <Link to="/register" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Register")}
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-gray-700 text-2xl"
            >
              <FontAwesomeIcon
                icon={isMobileMenuOpen ? faTimes : faBars}
                className="text-2xl"
              />
            </button>
          </div>
        </div>

        <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"} px-4 py-2`}>
          <div className="relative flex items-center mb-4">
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 text-gray-400 text-2xl"
            />
            <input
              type="text"
              className="w-full py-2 pl-10 pr-4 border rounded-md"
              placeholder={t("Search_placeholder")}
              onChange={debouncedHandleSearchChange}
            />
          </div>
          <div className="flex flex-col space-y-4">
            <Link to="/" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Home")}
            </Link>
            <Link to="/products" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Products")}
            </Link>
            <Link to="/orders" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              {t("Orders")}
            </Link>
            <Link to="/cart" className="text-lg font-bold text-gray-700 relative" onClick={handleLinkClick}>
              <div className="relative inline-block">
                <FontAwesomeIcon icon={faShoppingCart} className="text-2xl" />
                {cartTotal.total_quantity > 0 && (
                  <span className="absolute top-[-0.5rem] right-[-0.5rem] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                    {cartTotal.total_quantity}
                  </span>
                )}
              </div>
              <span className="ml-2">
                {cartTotal.currency} {cartTotal.total_price}
              </span>
            </Link>
            <Link to="/favorites" className="text-lg font-bold text-gray-700" onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faHeart} className="text-2xl" />
            </Link>
            <div className="text-lg font-bold text-gray-700 cursor-pointer">
              <Currencies onCurrencyChange={handleCurrencyChange} />
            </div>
            <LanguageSwitcher />
            {user.token && user.user && <Notifications />}
            <div className="relative mt-4" ref={dropdownRefMobile}>
              <button
                onClick={toggleDropdownMobile}
                className="text-lg font-bold text-gray-700 flex items-center space-x-2 focus:outline-none"
              >
                {user.token && user.user ? (
                  <>
                    {user.user.avatar ? (
                      <img
                      src={user.user.avatar ? getFullImageUrl(user.user.avatar) : defaultAvatar} 
                        alt="Avatar"
                        className="w-8 h-8 rounded-full"
                      />
                    ) : (
                      <span>{user.user.fullName}</span>
                    )}
                  </>
                ) : (
                  <FontAwesomeIcon icon={faUser} className="text-2xl" />
                )}
              </button>
              {isDropdownOpenMobile && (
                <div
                  className={`absolute ${direction === "rtl" ? "left-0" : "right-0"} w-48 mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-50`}
                >
                  {user.token && user.user ? (
                    <>
                      <Link to="/profile" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Profile")}
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                      >
                        {t("Logout")}
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to="/login" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Login")}
                      </Link>
                      <Link to="/register" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={handleLinkClick}>
                        {t("Register")}
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
