import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  VIEW_ORDER_REQUEST,
  VIEW_ORDER_SUCCESS,
  VIEW_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  RETURN_ORDER_REQUEST,
  RETURN_ORDER_SUCCESS,
  RETURN_ORDER_FAILURE,
  FETCH_RETURN_ORDER_DETAILS_REQUEST,
  FETCH_RETURN_ORDER_DETAILS_SUCCESS,
  FETCH_RETURN_ORDER_DETAILS_FAILURE,
} from '../actions/orderActions';
import { CLEAR_CART_ITEMS } from '../actions/cartActions';

const initialState = {
  orders: { pending: [], completed: [], cancelled: [], returnProduct: [] },
  order: null,
  returnOrderDetails: [],
  loading: false,
  error: null,
  current_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
    case CREATE_ORDER_REQUEST:
    case VIEW_ORDER_REQUEST:
    case DELETE_ORDER_REQUEST:
    case RETURN_ORDER_REQUEST:
    case FETCH_RETURN_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.data || { pending: [], completed: [], cancelled: [], returnProduct: [] },
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: {
          ...state.orders,
          pending: [...state.orders.pending, action.payload],
        },
      };
    case VIEW_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case DELETE_ORDER_SUCCESS:
      const { status, orderId } = action.payload;
      return {
        ...state,
        loading: false,
        orders: {
          ...state.orders,
          [status]: state.orders[status].filter((order) => order.id !== orderId),
        },
      };
    case RETURN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_RETURN_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        returnOrderDetails: action.payload,
      };
    case GET_ORDERS_FAILURE:
    case CREATE_ORDER_FAILURE:
    case VIEW_ORDER_FAILURE:
    case DELETE_ORDER_FAILURE:
    case RETURN_ORDER_FAILURE:
    case FETCH_RETURN_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_CART_ITEMS:
      return {
        ...state,
        orders: { pending: [], completed: [], cancelled: [], returnProduct: [] },
      };
    default:
      return state;
  }
};

export default orderReducer;
