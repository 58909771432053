// services/notificationsService.js
import axiosInstance from '../utils/axiosInstance';

export const fetchNotifications = async () => {
  const response = await axiosInstance.get('/notifications');
  return response.data.data;
};

export const fetchNotificationById = async (id) => {
  const response = await axiosInstance.get(`/notifications/${id}`);
  return response.data.data;
};

export const deleteNotification = async (id) => {
  await axiosInstance.delete(`/notifications/${id}`);
};

export const deleteAllNotifications = async () => {
  await axiosInstance.delete('/notifications/delete/all-notifications');
};

export const getUserNotificationsCount = async () => {
  const response = await axiosInstance.get('/notifications/user/count');
  return response.data.count;
};

export const getUserNotifications = async () => {
  const response = await axiosInstance.get('/notifications/user/notifications');
  return response.data.data;
};

export const markNotificationAsRead = async (id) => {
  const response = await axiosInstance.put(`/notifications/mark-as-read/${id}`);
  return response.data.data;
};
