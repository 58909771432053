import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProductById } from "../../services/api";
import { useTranslation } from "react-i18next";
import { getFullImageUrl, defaultAvatar } from "../../utils/imageUtils";
import { addToCartAction } from "../../redux/actions/cartActions"; // Import the addToCartAction
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faExpand, faStar } from "@fortawesome/free-solid-svg-icons";
import { createRating } from "../../services/ratingService";
import {
  addFavorite,
  removeFavorite,
} from "../../redux/actions/favoriteActions";
import { useDispatch, useSelector } from "react-redux";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-image-lightbox";
import { Modal } from "react-bootstrap";
import "react-image-lightbox/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import "./Ecommerce.css";
import MetaTags from "../../components/MetaTags";
import EcommerceCard from "../../components/EcommerceCard";

const ProductDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showRatingsModal, setShowRatingsModal] = useState(false);
  const { i18n, t } = useTranslation();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRate, setIsLoadingRate] = useState(false);
  
  const fetchProductDetails = async () => {
    try {
      const productData = await getProductById(id);
      setProduct(productData.data);
      const relatedProductsArray = Object.values(productData.related_products);
      setProducts(relatedProductsArray);
      setSelectedImage(getFullImageUrl(productData.data.images[0]));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  const handleAttributeChange = (attributeTitle, valueId) => {
    setSelectedAttributes({
      ...selectedAttributes,
      [attributeTitle]: valueId,
    });
  };

  const handleAddToCart = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        addToCartAction(id, quantity, Object.values(selectedAttributes))
      );
      Swal.fire("Success", "Product added to cart successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to add product to cart.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const memoizedImages = useMemo(
    () => product?.images.map(getFullImageUrl),
    [product]
  );

  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    if (token) {
      if (product.favorite == 1) {
        dispatch(removeFavorite(product.id));
      } else {
        dispatch(addFavorite(product.id));
      }
      setProduct((prevProduct) => ({
        ...prevProduct,
        favorite: prevProduct.favorite == 1 ? 0 : 1,
      }));
    } else {
      Swal.fire({
        title: t("please_log_in"),
        text: t("need_to_log_in_to_place_order"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("log_in"),
        cancelButtonText: t("cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
    }
  };
 
  const description = product
    ? i18n.language === 'ar'
      ? product.ar_description
      : product.en_description
    : '';

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleThumbnailHover = (image, index) => {
    setSelectedImage(getFullImageUrl(image));
    setPhotoIndex(index);
  };

  const handleRatingSubmit = async () => {
    if (!token) {
      Swal.fire({
        title: t("please_log_in"),
        text: t("need_to_log_in_to_place_order"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("log_in"),
        cancelButtonText: t("cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      return;
    }
  
    setIsLoadingRate(true);
  
    try {
      const response = await createRating(product.id, rating, comment);
  
      if (response.valid === 1) {
        Swal.fire(
          t("success"),
          response.message || t("rating_submitted_successfully"),
          "success"
        );
        setComment("");
        setRating(0);
        fetchProductDetails();
      } else {
        // Check if there are validation errors returned in the response
        if (response.errors) {
          // Extract and concatenate the error messages
          const errorMessages = Object.values(response.errors).flat().join('\n');
          Swal.fire(
            t("error"),
            errorMessages || t("error_submitting_rating"),
            "error"
          );
        } else {
          Swal.fire(
            t("error"),
            response.message || t("error_submitting_rating"),
            "error"
          );
        }
      }
    } catch (error) {
      console.error("Failed to submit rating:", error);
      Swal.fire(
        t("error"),
        error.response?.data?.message || t("error_occurred"),
        "error"
      );
    } finally {
      setIsLoadingRate(false);
    }
  };
  

  const handleOpenRatingsModal = () => {
    setShowRatingsModal(true);
  };

  const handleCloseRatingsModal = () => {
    setShowRatingsModal(false);
  };

  if (loading) {
    return <div className="text-center my-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center my-8 text-danger">Error: {error}</div>;
  }

  return (
    <>
      <MetaTags title={t("Product_details")} />
      <div className="container mt-8">
        {product && (
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="position-relative">
                  <div className="image-magnify-container">
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Selected",
                          isFluidWidth: true,
                          src: selectedImage,
                          sizes: "100",
                        },
                        largeImage: {
                          src: selectedImage,
                          width: 1000,
                          height: 1500,
                        },
                        enlargedImageContainerStyle: { zIndex: 9 },
                        enlargedImagePosition: "over",
                      }}
                    />
                    <button
                      className="btn btn-light w-25 position-absolute top-0 end-0 m-2"
                      onClick={() => setIsLightboxOpen(true)}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  </div>
                  <div className="d-flex mt-2 overflow-auto product-thumbnails">
                    {product.images.map((image, index) => (
                      <div key={index} className="me-2 thumbnail-wrapper">
                        <img
                          src={getFullImageUrl(image)}
                          alt={`Thumbnail ${index + 1}`}
                          className="img-thumbnail"
                          onMouseEnter={() =>
                            handleThumbnailHover(image, index)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column">
              <h2 className="mx-auto text-center">
                  {i18n.language === "ar" ? product.ar_title : product.en_title}
                </h2>
                <div className="mt-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>{t("category")}:</strong>{" "}
                        {i18n.language === "ar"
                          ? product.category.ar_name
                          : product.category.en_name}
                      </p>
                    </div>
                    {product.subcategory && (
                      <div className="col-12 col-md-6">
                        <p>
                          <strong>{t("subcategory")}:</strong>{" "}
                          {i18n.language === "ar"
                            ? product.subcategory.ar_name
                            : product.subcategory.en_name}
                        </p>
                      </div>
                    )}
                    
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>{t("price")}:</strong>{" "}
                        {product.discount.discount > 0 ? (
                          <>
                            <span className="text-danger">
                              {product.discounted_price} {product.currency}
                            </span>{" "}
                            <span className="text-muted text-decoration-line-through">
                              {product.price} {product.currency}
                            </span>
                          </>
                        ) : (
                          <>
                            {product.price} {product.currency}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="col-12">
                    <p>
                      <strong>{t('description')}:</strong>{' '}
                      {showFullDescription ? description : `${description.substring(0, 500)}...`}
                    </p>
                    {description.length > 500 && (
                      <button onClick={toggleDescription} className="text-blue-500 hover:underline">
                        {showFullDescription ? t('See_less') : t('See_more')}
                      </button>
                    )}
                  </div>
                    <div className="col-12  ">
                    <span className="d-block mb-2">
                      {product.stock > 0 ? t("available") : t("not_available")}
                    </span>
                    <span className="d-block">
                      {t("new")}
                    </span>
                  </div>

                   

                    {product.details && (
                      <>
                        {product.details.manufacturer && (
                          <div className="col-12 col-md-6">
                            <p>
                              <strong>{t("manufacturer")}:</strong>{" "}
                              {product.details.manufacturer}
                            </p>
                          </div>
                        )}
                        {product.details.country_of_origin && (
                          <div className="col-12 col-md-6">
                            <p>
                              <strong>{t("country_of_origin")}:</strong>{" "}
                              {product.details.country_of_origin}
                            </p>
                          </div>
                        )}
                        {product.details.warranty && (
                          <div className="col-12 col-md-6">
                            <p>
                              <strong>{t("warranty")}:</strong>{" "}
                              {product.details.warranty}
                            </p>
                          </div>
                        )}
                        {product.details.additional_features && (
                          <div className="col-12 col-md-6">
                            <p>
                              <strong>{t("additional_features")}:</strong>{" "}
                              {product.details.additional_features}
                            </p>
                          </div>
                        )}
                        {product.details.model_number && (
                          <div className="col-12 col-md-6">
                            <p>
                              <strong>{t("model_number")}:</strong>{" "}
                              {product.details.model_number}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="mt-4">
  <h3 className="text-center">{t("select_attributes")}</h3>
  <div className="flex flex-wrap">
    {Object.entries(product.attributes).map(([key, attributeGroup]) => (
      <div key={key} className="mt-2 w-full md:w-1/2 px-2">
        <h3 className="text-center">
          {i18n.language === "ar" ? attributeGroup.ar_key : attributeGroup.en_key}
        </h3>
        <select
          onChange={(e) =>
            handleAttributeChange(attributeGroup.en_key, e.target.value)
          }
          className="form-select mt-2 w-full mx-auto"
        >
          <option value="">{t("select")}</option>
          {attributeGroup.attributes.map((attribute) => (
            <option key={attribute.id} value={attribute.id}>
              {i18n.language === "ar" ? attribute.ar_value : attribute.en_value}
            </option>
          ))}
        </select>
      </div>
    ))}
  </div>
</div>

<div className="mt-4">
  <h3 className="text-center">{t("quantity")}</h3>
  <div className="flex justify-center items-center mt-2">
    <input
      type="number"
      value={quantity}
      onChange={(e) => setQuantity(Number(e.target.value))}
      min="1"
      className="form-control w-25 mr-4"
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleAddToCart}
      disabled={isLoading}
      style={{ backgroundColor: 'rgba(144, 158, 169, 1)' }}
    >
      {isLoading ? (
        <CircularProgress size={24} style={{ color: 'white' }} />
      ) : (
        t("add_to_cart")
      )}
    </Button>
  </div>
</div>

              </div>
            </div>
            <div className="mt-4">
              <div className="button-container">
                <button
                  onClick={handleToggleFavorite}
                  className={`btn btn-link ${
                    product.favorite == 1 ? "text-danger" : "text-secondary"
                  }`}
                  style={{ fontSize: "40px" }}
                >
                  <FontAwesomeIcon icon={faHeart} />
                </button>
                <button
                  onClick={handleOpenRatingsModal}
                  className="btn btn-link text-secondary rating-button"
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className="rating-icon"
                    style={{ fontSize: "40px", color: "gold" }}
                  />
                  <span className="rating-count">{product.ratings.length}</span>
                </button>
              </div>

              <h2 className="h2">{t("add_rating")}</h2>

              <Rating
                name="rating"
                value={rating}
                onChange={(e, newValue) => setRating(newValue)}
                style={{ direction: "ltr" }}
              />
              <TextField
                label={t("comment")}
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
                className="mt-4 w-100"
              />
              <Button
                variant="contained"
                color="primary"
                className="mt-4"
                onClick={handleRatingSubmit}
                disabled={isLoadingRate}
                style={{ backgroundColor: 'rgba(144, 158, 169, 1)' }}
              >
                {isLoadingRate ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                  t("submit_rating")
                )}
              </Button>
            </div>
          </div>
        )}

        {products.length === 0 ? (
          <div className="text-center text-red-500">
            {i18n.t("noProductsFound")}
          </div>
        ) : (
          <>
           <h1 className='text-center mb-4'>{i18n.t('related_products')}</h1>
          <div className="home-section-content position-relative d-flex align-items-center">
            <div className="home-cards-container container d-flex overflow-auto scroll-smooth w-100">
              {products.length > 0 ? (
                products.map((product, index) => (
                  <div
                    className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 d-flex justify-content-center"
                    key={index}
                  >
                    <EcommerceCard
                      productId={product.id}
                      images={product.images}
                      title={
                        i18n.language === "ar"
                          ? product.ar_title
                          : product.en_title
                      }
                      price={parseFloat(product.price)}
                      description={
                        i18n.language === "ar"
                          ? product.ar_description
                          : product.en_description
                      }
                      discount={product.discount ? product.discount : 0}
                      discountType={product.discount_type}
                      discountedPrice={parseFloat(product.discounted_price)}
                      tagText={product.tag_text}
                      isNew={product.is_new}
                      tagColor={product.tag_color}
                      currency={product.currency}
                      favorite={product.favorite}
                      cart={product.cart}
                      stock={product.stock}
                      className="w-100 "
                    />
                  </div>
                ))
              ) : (
                <p>No products available</p>
              )}
            </div>
          </div>
          </>

        )}

        {isLightboxOpen && (
          <Lightbox
            mainSrc={memoizedImages[photoIndex]}
            nextSrc={memoizedImages[(photoIndex + 1) % memoizedImages.length]}
            prevSrc={
              memoizedImages[
                (photoIndex + memoizedImages.length - 1) % memoizedImages.length
              ]
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + memoizedImages.length - 1) % memoizedImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % memoizedImages.length)
            }
          />
        )}

        <Modal show={showRatingsModal} onHide={handleCloseRatingsModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t("ratings")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {product.ratings && product.ratings.length > 0 ? (
              product.ratings.map((rating) => (
                <div
                  key={rating.id}
                  className={`comment ${
                    i18n.language === "ar" ? "rtl" : "ltr"
                  }`}
                >
                  <img
                    src={
                      rating.avatar
                        ? getFullImageUrl(rating.avatar)
                        : defaultAvatar
                    }
                    alt={rating.username}
                    className="avatar"
                  />
                  <div className="comment-content">
                    <p className="comment-user">{rating.username}</p>
                    <Rating value={rating.rating} readOnly />
                    <p className="comment-text">{rating.review}</p>
                    <small className="comment-date">
                      {new Date(rating.updated_at).toLocaleString()}
                    </small>
                  </div>
                </div>
              ))
            ) : (
              <p>{t("no_ratings")}</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ProductDetailsPage;
