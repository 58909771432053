import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createNewOrder } from '../../redux/actions/orderActions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { applyDiscountCode } from '../../services/orderService';
import { useTranslation } from 'react-i18next';
import MetaTags from '../../components/MetaTags';
import DiscountCodeModal from '../../components/DiscountCodeModal';

const CheckoutPage = ({ currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.items);
  const user = useSelector((state) => state.auth.user);
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountDetails, setDiscountDetails] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleApplyDiscountCode = async () => {
    try {
      const response = await applyDiscountCode(discountCode);
      setDiscountDetails(response.discountCode);
      Swal.fire(t('success'), `${t('discount_applied')}: ${response.discountCode.code}. ${t('discount_value')}: ${response.discountCode.discount_value}`, 'success');
      setShowModal(false);
    } catch (error) {
      Swal.fire(t('error'), error.response.data.message, 'error');
    }
  };

  const handlePlaceOrder = () => {
    if (user && user.id) {
      Swal.fire({
        title: t('confirm_order'),
        text: t('confirm_order_text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('yes_place_order'),
        cancelButtonText: t('no_cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          const discountCodeId = discountDetails ? discountDetails.id : null;
          dispatch(createNewOrder(user.id, cartItems[0].cart_id, currency, address, description, discountCodeId))
            .then((data) => {
              if (data.valid === 1) {
                Swal.fire(t('success'), data.message || t('order_placed_successfully'), 'success').then(() => {
                  setOrderPlaced(true);
                });
              } else {
                setErrors(data.errors || []);
                Swal.fire(t('error'), data.message || t('an_error_occurred'), 'error');
              }
            })
            .catch((error) => {
              const errorMessage = error.errors ? error.errors.join(', ') : error.message;
              Swal.fire(t('error'), errorMessage, 'error');
            });
        }
      });
    } else {
      Swal.fire({
        title: t('please_log_in'),
        text: t('need_to_log_in_to_place_order'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('log_in'),
        cancelButtonText: t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    }
  };

  const calculateTotal = () => {
    const subtotal = cartItems.reduce((total, item) => total + item.product_price * item.quantity, 0);
    if (discountDetails) {
      return subtotal - discountDetails.discount_value;
    }
    return subtotal;
  };

  if (orderPlaced) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-4">{t('order_confirmation')}</h1>
        <p className="text-center">{t('order_placed_successfully_message')}</p>
      </div>
    );
  }

  return (
    <>
      <MetaTags title={t('Checkout')} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">{t('shipping_address')}</h2>
          <textarea
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={`w-full border p-2 rounded mb-4 ${errors.address ? 'border-red-500' : ''}`}
            rows="4"
          ></textarea>
          {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}

          <h2 className="text-xl font-semibold mb-2">{t('description')}</h2>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full border p-2 rounded mb-4"
            rows="4"
          ></textarea>

          <h2 className="text-xl font-semibold mb-2">{t('discount_code')}</h2>
          <div className="flex">
            <button
              onClick={() => setShowModal(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {t('apply')}
            </button>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-2">{t('order_summary')}</h2>
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="mb-2">
                <div className="flex justify-between">
                  <span>{item.product_ar_title} x {item.quantity}</span>
                  <span>{currency} - {item.product_price * item.quantity}</span>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex justify-between font-semibold mt-4">
            <span>{t('total')}</span>
            <span>{currency} - {calculateTotal()}</span>
          </div>
          <button
            onClick={handlePlaceOrder}
            className="mt-4 w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            {t('place_order')}
          </button>
          {errors.length > 0 && (
            <div className="mt-4">
              {errors.map((error, index) => (
                <p key={index} className="text-red-500 text-sm">{error}</p>
              ))}
            </div>
          )}
        </div>
      </div>
      <DiscountCodeModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
        handleApplyDiscountCode={handleApplyDiscountCode}
      />
    </>
  );
};

export default CheckoutPage;
