import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const AuthGuard = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (!token) {
      Swal.fire({
        title: t('please_log_in'),
        text: t('need_to_log_in_to_place_order'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('log_in'),
        cancelButtonText: t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        } else {
          navigate(-1); // Go back to the previous route
        }
      });
    }
  }, [token, t, navigate]);

  if (!token) {
    return null; // Render nothing if not authenticated
  }

  return children; // Render children if authenticated
};

export default AuthGuard;
