import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAds } from '../redux/actions/adsActions';
import { getFullImageUrl } from '../utils/imageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './css/AdsNavBar.css';
import './css/FullScreenTable.css';

const AdsNavBar = () => {
  const dispatch = useDispatch();
  const { allAds, loading, error } = useSelector((state) => state.ads);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const navRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    dispatch(fetchAllAds());
  }, [dispatch]);

  const handleAdClick = (ad) => {
    setSelectedAd(ad);
    setIsImageLoaded(false);
  };

  const handleClose = () => {
    setSelectedAd(null);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="ads-navbar-container">
      <div className="ads-navbar" ref={navRef}>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {allAds.map((ad) => (
          <span key={ad.id} className="ad-title" onClick={() => handleAdClick(ad)}>
            {ad.title}
          </span>
        ))}
      </div>

      {selectedAd && (
        <div className={`fixed-inset-0 ${selectedAd ? 'visible' : ''}`}>
          <div className={`relative-popup ${selectedAd ? 'visible' : ''}`} ref={popupRef}>
            {!isImageLoaded && <div className="loading-spinner">Loading...</div>}
            <img
              src={getFullImageUrl(selectedAd.image)}
              alt={selectedAd.title}
              className={`popup-image ${isImageLoaded ? 'visible' : 'hidden'}`}
              onLoad={handleImageLoad}
            />
            <div className="overlay-content">
              <button onClick={handleClose} className="close-button" aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />
              </button>
              {isImageLoaded && (
                <div className="flex flex-col h-full justify-center">
                  <div className="mb-4" dangerouslySetInnerHTML={{ __html: selectedAd.content }}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdsNavBar;
