import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { addFavorite, removeFavorite } from '../redux/actions/favoriteActions';
import { useNavigate } from 'react-router-dom';
import { getFullImageUrl } from '../utils/imageUtils';
import './css/Ecommerce.css';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

const EcommerceCard = ({ productId, images, title, price, description, discount, discountType, discountedPrice, isNew, tagText, tagColor, currency, favorite, stock }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useSelector((state) => state.auth.token);

  const [mainImage, setMainImage] = useState(getFullImageUrl(images[0].image));
  const [isFavorite, setIsFavorite] = useState(favorite === 1);
  const SAR = t('SAR');

  useEffect(() => {
    setIsFavorite(favorite === 1);
  }, [favorite]);

  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    if (token) {
      if (isFavorite) {
        dispatch(removeFavorite(productId));
      } else {
        dispatch(addFavorite(productId));
      }
      setIsFavorite(!isFavorite);
    } else {
      Swal.fire({
        title: t('please_log_in'),
        text: t('need_to_log_in_to_place_order'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('log_in'),
        cancelButtonText: t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    }
  };

  const handleCardClick = () => {
    navigate(`/product/${productId}`);
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    navigate(`/product/${productId}`);
  };

  const handleThumbnailHover = (image) => {
    setMainImage(getFullImageUrl(image.image));
  };

  return (
    <div
      className={`max-w-xs w-full rounded overflow-hidden shadow-lg relative cursor-pointer ${stock < 10 ? 'border-4 border-red-500' : ''}`}
      onClick={handleCardClick}
    >
      {tagText && (
        <div
          className="absolute top-0 right-0 mt-2 ml-2 py-1 px-2 rounded-full"
          style={{ backgroundColor: tagColor || '#808080', color: '#ffffff' }}
        >
          <span className="block whitespace-nowrap">{tagText}</span>
        </div>
      )}

      {isNew === 1 && (
        <div
          className="absolute top-0 left-0 mt-2 ml-2 py-1 px-2 w-14 h-14 flex items-center justify-center rounded-full"
          style={{ backgroundColor: 'rgba(143, 158, 170, 1)', color: '#ffffff' }}
        >
          <span className="block whitespace-nowrap">{t('new')}</span>
        </div>
      )}

      {discount && discount > 0 && (
        <div
          className="absolute top-20 left-0 mt-2 ml-2 py-1 px-2 w-20 h-14 flex items-center justify-center rounded-full"
          style={{ backgroundColor: 'rgba(221, 29, 29, 1)', color: '#ffffff' }}
        >
          {discount}{discountType === 'percentage' ? '%' : SAR}
        </div>
      )}

      {images && images.length > 0 && (
        <div className="zoom-container w-full h-64">
          <img src={mainImage} alt="Main product" className="zoom-image w-full h-full object-cover" />
        </div>
      )}
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-2">
          <div className="font-small text-s mb-2">{title}</div>
          <div className="text-s mb-2">
            {discount && discount > 0 ? (
              <div>
                <span className="line-through text-red-500">{currency} {Math.max(0, parseFloat(price)).toFixed(2)}</span> &nbsp;
                <span>{currency} {Math.max(0, parseFloat(discountedPrice)).toFixed(2)}</span>
              </div>
            ) : (
              <span>{currency} {Math.max(0, parseFloat(price)).toFixed(2)}</span>
            )}
          </div>
        </div>
      </div>
      <div className="text-gray-700 text-base description text-center p-2">
        {description.length > 100 ? `${description.substring(0, 100)}...` : description}
      </div>

      <div className="px-6 pt-4 pb-2 flex justify-between">
        <button
          onClick={handleToggleFavorite}
          className={`text-${isFavorite ? 'red' : 'gray'}-500 text-2xl`}
        >
          <FontAwesomeIcon icon={faHeart} />
        </button>
        {stock < 10 && (
          <span className="text-red-500 font-bold">
            {t('only')} {stock} {t('left_in_stock')}
          </span>
        )}
        <button onClick={handleIconClick} className="text-gray-500 text-2xl">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      {images && images.length > 0 && (
        <div className="px-6 pt-4 pb-2 scroll-container">
          {images.map((image, index) => (
            <img
              key={index}
              src={getFullImageUrl(image.image)}
              alt={image.caption || "Product Image"}
              className="w-12 h-12 object-cover cursor-pointer border border-gray-200 rounded-full"
              onMouseEnter={() => handleThumbnailHover(image)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EcommerceCard;
