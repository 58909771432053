import axiosInstance from '../utils/axiosInstance';

export const getProducts = async ({
  priceFrom,
  priceTo,
  nameFilter,
  selectedCategory,
  selectedSubcategory,
  selectedSubsubcategory,
  section = null, // Default value for section
  page,
  limit,
}) => {
  try {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const response = await axiosInstance.get('/products', {
      params: {
        currency: selectedCurrency,
        priceFrom,
        priceTo,
        nameFilter,
        category: selectedCategory,
        subcategory: selectedSubcategory,
        subsubcategory: selectedSubsubcategory,
        section, // Include section in the request
        page,
        limit,
      },
    });
    return {
      data: response.data.data,
      total: response.data.total,
    };
  } catch (error) {
    throw error;
  }
};


export const getProductById = async (id) => {
  try {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const response = await axiosInstance.get(`/products/${id}`, {
      params: { currency: selectedCurrency },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductAttributes = async (id) => {
  try {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const response = await axiosInstance.get(`/products/${id}/attributes`, {
      params: { currency: selectedCurrency },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSectionProducts = async () => {
  try {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const response = await axiosInstance.get('/products/fetch/section-products', {
      params: { currency: selectedCurrency },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


export const getProductsByCategory = async (categoryId, subcategoryId, filters) => {
  try {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const response = await axiosInstance.get(`/products/category/${categoryId}/subcategory`, {
      params: { subcategoryId, currency: selectedCurrency, ...filters },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


