import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRandomAd } from '../redux/actions/appActions';
import { getFullImageUrl } from '../utils/imageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './css/FullScreenTable.css';

const AdPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const { randomAd, loading, error } = useSelector((state) => state.app);
  const popupRef = useRef(null);

  useEffect(() => {
    const adShown = sessionStorage.getItem('adShown');
    if (!adShown) {
      dispatch(fetchRandomAd());
      setIsVisible(true);
    }

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  const handleClose = () => {
    sessionStorage.setItem('adShown', 'true');
    setIsVisible(false);
  };

  return (
    <div className={`fixed-inset-0 ${isVisible ? 'visible' : ''}`}>
      <div className={`relative-popup ${isVisible ? 'visible' : ''}`} ref={popupRef}>
        {loading && <p className="loading-spinner">Loading...</p>}
        {error && <p className="error-message">Error: {error}</p>}
        {randomAd && (
          <>
            <img
              src={randomAd ? getFullImageUrl(randomAd.image) : ''}
              alt={randomAd ? randomAd.title : ''}
              className="popup-image"
            />
            <div className="overlay-content text-center">
              <button onClick={handleClose} className="close-button" aria-label="Close">
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className="flex flex-col h-full justify-center text-sm md:text-base">
                <h2 className="text-xl md:text-3xl font-bold ">{randomAd.title}</h2>
                <div  dangerouslySetInnerHTML={{ __html: randomAd.content }}></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdPopup;
