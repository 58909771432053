import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RedirectIfAuthenticated = ({ children }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      navigate(-1); 
    }
  }, [token, navigate]);

  return children;
};

export default RedirectIfAuthenticated;
