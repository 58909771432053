import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrencies } from '../redux/actions/appActions';

const Currencies = ({ onCurrencyChange }) => {
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.app.currencies);
  const loading = useSelector((state) => state.app.loading);
  const error = useSelector((state) => state.app.error);
  const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem('selectedCurrency') || 'SAR'); 

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('selectedCurrency', selectedCurrency);
    onCurrencyChange(selectedCurrency);
  }, [selectedCurrency, onCurrencyChange]);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
    window.location.reload();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading currencies: {error}</p>;
  }

  return (
    <div className="currencies">
      <select className="currency-dropdown" value={selectedCurrency} onChange={handleCurrencyChange}>
        {currencies.map((currency) => (
          <option key={currency.code} value={currency.code}>{currency.code}</option>
        ))}
      </select>
    </div>
  );
};

export default Currencies;
