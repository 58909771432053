import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import {
  getUserNotificationsCountAction,
  getUserNotificationsAction,
  markNotificationAsReadAction,
  deleteNotificationAction,
  deleteAllNotificationsAction,
} from '../redux/actions/notificationsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDirection } from '../context/DirectionContext';
import './css/Notifications.css';

const Notifications = () => {
  const { t, i18n } = useTranslation();
  const { direction } = useDirection();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notifications);
  const initialCount = useSelector((state) => state.notifications.count);
  const user = useSelector((state) => state.auth);

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [count, setCount] = useState(initialCount);
  const notificationsRef = useRef(null);

  useEffect(() => {
    if (user.token) {
      dispatch(getUserNotificationsCountAction()).then((result) => {
        if (result.payload) {
          setCount(result.payload);
        }
      });
      dispatch(getUserNotificationsAction());
    }

    const pusher = new Pusher('88d5225f33525c453fbc', {
      cluster: 'eu',
      authEndpoint: '/broadcasting/auth',
      auth: {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      },
    });

    const notificationsChannel = pusher.subscribe('notifications');
    notificationsChannel.bind('App\\Events\\NotificationSent', function(data) {
      setCount((prevCount) => prevCount + 1);
      dispatch(getUserNotificationsAction());
    });

    const orderStatusChannel = pusher.subscribe('orderStatus');
    orderStatusChannel.bind('App\\Events\\OrderStatusUpdated', function(data) {
      setCount((prevCount) => prevCount + 1);
      dispatch(getUserNotificationsAction());
    });

    return () => {
      notificationsChannel.unbind('App\\Events\\NotificationSent');
      orderStatusChannel.unbind('App\\Events\\OrderStatusUpdated');
      pusher.unsubscribe('notifications');
      pusher.unsubscribe('orderStatus');
    };
  }, [dispatch, user.token]);

  const handleToggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleMarkAsRead = (id) => {
    const notification = notifications.find((n) => n.id === id);
    if (notification && !notification.seen) {
      dispatch(markNotificationAsReadAction(id)).then((result) => {
        if (result.payload) {
          setCount((prevCount) => prevCount - 1);
        }
      });
    } else {
      dispatch(markNotificationAsReadAction(id));
    }
  };

  const handleDeleteNotification = (id) => {
    dispatch(deleteNotificationAction(id)).then(() => {
      setCount((prevCount) => prevCount - 1);
    });
  };

  const handleDeleteAllNotifications = () => {
    dispatch(deleteAllNotificationsAction()).then(() => {
      setCount(0);
    });
  };

  const handleClickOutside = (event) => {
    if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderNotificationMessage = (notification) => {
    const maxLength = 100;
    let message = notification.message;
  
    if (message.length > maxLength) {
      message = message.substring(0, maxLength) + '...';
    }
  
    return message;
  };
  

  const renderNotificationType = (notification) => {
    const typeTranslations = {
      ApiNotification: { en: 'Notification', ar: 'إشعار' },
      OrdersReturned: { en: 'Order Return', ar: 'إرجاع طلب' },
      Order: { en: 'Order', ar: 'طلب شراء' },
    };
  
    const type = notification.type.split('\\').pop();
    const language = i18n.language;
  
    if (typeTranslations[type]) {
      return typeTranslations[type][language];
    } else {
      return type;
    }
  };
  
  return (
    <div className="relative" ref={notificationsRef}>
      <button
        onClick={handleToggleNotifications}
        className="relative text-lg font-bold text-gray-700 flex items-center space-x-2 focus:outline-none"
      >
        <FontAwesomeIcon icon={faBell} className="text-2xl " />
        {count > 0 && (
          <span className="absolute top-[-0.5rem] right-[-0.5rem] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
            {count}
          </span>
        )}
      </button>
      {isNotificationsOpen && (
        <div className={`absolute ${direction === 'rtl' ? 'left-0' : 'right-0'} w-64 mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-50`}>
          <div className="p-4 flex justify-between items-center">
            <span className="font-bold">{t('Notifications')}</span>
            <button
              onClick={handleDeleteAllNotifications}
              className="text-red-600 hover:text-red-800 focus:outline-none"
            >
              {t('Delete All')}
            </button>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <div key={notification.id} className="p-4 flex justify-between items-center hover:bg-gray-100 cursor-pointer">
                  <div className="flex flex-col" onClick={() => handleMarkAsRead(notification.id)}>
                    <p className={notification.seen ? 'text-gray-500' : 'font-bold'}>
                      {renderNotificationType(notification)}
                    <br/>
                      {renderNotificationMessage(notification)}
                    </p>
                    <span className="text-xs text-gray-500">{notification.created_at}</span>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleMarkAsRead(notification.id)}
                      className="text-green-600 hover:text-green-800 focus:outline-none"
                    >
                      <FontAwesomeIcon icon={notification.seen ? faEye : faEyeSlash} />
                    </button>
                    <button
                      onClick={() => handleDeleteNotification(notification.id)}
                      className="text-red-600 hover:text-red-800 focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center">
                <p>{t('No_notifications')}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
