import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from '../i18n';

const DirectionContext = createContext();

export const DirectionProvider = ({ children }) => {
  const [direction, setDirection] = useState(localStorage.getItem('direction') || 'ltr');

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      const newDirection = lng === 'ar' ? 'rtl' : 'ltr';
      setDirection(newDirection);
      localStorage.setItem('direction', newDirection); 
      document.documentElement.dir = newDirection;
      document.documentElement.lang = lng;
    };

    i18n.on('languageChanged', handleLanguageChange);

    handleLanguageChange(i18n.language);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <DirectionContext.Provider value={{ direction, setDirection }}>
      {children}
    </DirectionContext.Provider>
  );
};

export const useDirection = () => useContext(DirectionContext);
