// services/ads.js
import axiosInstance from '../utils/axiosInstance';

export const getRandomAd = async () => {
  try {
    const response = await axiosInstance.get('/randomAd');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching random ad:', error);
    throw error;
  }
};

export const getAllAds = async () => {
  try {
    const response = await axiosInstance.get('/getAllAds');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching all ads:', error);
    throw error;
  }
};
