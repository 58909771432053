import axios from "axios";
import axiosRetry from "axios-retry";
import { API_BASE_URL } from "../config/apiConfig";
import { getCurrentLanguage } from "../utils/languageUtils";
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const newLanguage = getCurrentLanguage();

    const token = Cookies.get("authToken");
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");

    config.params = config.params || {};
    config.params["lang"] = newLanguage;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(axiosInstance, {
  retries: 3, 
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return error.response && error.response.status === 429; 
  },
});

export default axiosInstance;
