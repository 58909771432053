import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductsByCategory } from '../../redux/actions/productActions';
import { fetchCategoryById, fetchCategories } from '../../redux/actions/categoryActions';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import EcommerceCard from '../../components/EcommerceCard';
import { getFullImageUrl } from '../../utils/imageUtils';
import MetaTags from '../../components/MetaTags';

const CategoryProducts = () => {
  const { categoryId, subcategoryId } = useParams();
  const dispatch = useDispatch();
  const [showCategories, setShowCategories] = useState(false);
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [nameFilter, setNameFilter] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSubsubcategory, setSelectedSubsubcategory] = useState('');
  const categoryRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const category = useSelector((state) => state.category.category);
  const categories = useSelector((state) => state.category.categories);
  const subcategories = useSelector((state) => state.category.category?.subcategories || []);
  const categoryProducts = useSelector((state) => state.products.categoryProducts) || [];
  const loading = useSelector((state) => state.category.loading || state.products.loading);
  const error = useSelector((state) => state.category.error || state.products.error);

  useEffect(() => {
    dispatch(fetchCategoryById(categoryId));
    dispatch(fetchProductsByCategory(categoryId, subcategoryId));
    dispatch(fetchCategories());
  }, [dispatch, categoryId, subcategoryId]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(fetchCategoryById(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  useEffect(() => {
    if (showCategories) {
      categoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCategories]);

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    dispatch(fetchProductsByCategory(categoryId, subcategoryId, {
      priceFrom,
      priceTo,
      nameFilter,
      selectedCategory,
      selectedSubcategory,
      page: newPage,
      limit: itemsPerPage,
    }));
  };

  const renderSubcategories = (subcategories) => {
    return subcategories.flatMap((subcategory) => [
      <div key={subcategory.id} className="col-span-6 p-2 border">
        <Link to={`/categories/${categoryId}/subcategory/${subcategory.id}`}>
          <h3>{i18n.language === 'ar' ? subcategory.ar_name : subcategory.en_name}</h3>
          <div
            style={{ backgroundImage: `url(${getFullImageUrl(subcategory.image)})` }}
            className="h-24 bg-cover bg-center mb-2"
          ></div>
        </Link>
      </div>,
      ...(subcategory.children ? renderSubcategories(subcategory.children) : [])
    ]);
  };

  return (
    <>
      <MetaTags title={t('Category_products')} />
      <div className="mx-auto mt-4 flex flex-col">
        <div className="w-full flex justify-center items-center mb-4 md:hidden">
          <button
            className={`bg-blue-500 text-white p-2 rounded mx-2 ${showCategories ? 'bg-blue-700' : ''}`}
            onClick={toggleCategories}
          >
            {showCategories ? t('Hide Categories') : t('Show Categories')}
          </button>
        
        </div>
        <div className={`flex ${i18n.dir() === 'rtl' ? 'flex-row-reverse' : 'flex-row'}`}>
          <div ref={categoryRef} className={`w-1/6 p-2 border-r hidden md:flex flex-col ${i18n.dir() === 'rtl' ? 'order-last' : 'order-first'}`}>
            <div className="w-full flex justify-center items-center mb-4">
              <button
                className={`bg-blue-500 text-white p-2 rounded mx-2 ${showCategories ? 'bg-blue-700' : ''}`}
                onClick={toggleCategories}
              >
                {t('Categories')}
              </button>
            
            </div>
            <div className="flex-1 overflow-y-auto max-h-[calc(100vh-100px)]">
              {showCategories && category && (
                <>
                  <Link to={`/category/${categoryId}`}>
                    <h1>{i18n.language === 'ar' ? category.ar_name : category.en_name}</h1>
                    <div
                      style={{ backgroundImage: `url(${getFullImageUrl(category.image)})` }}
                      className="h-32 bg-cover bg-center mb-4"
                    ></div>
                  </Link>
                  <div className="grid grid-cols-1 gap-2">
                    {category.subcategories && renderSubcategories(category.subcategories)}
                  </div>
                </>
              )}
             
            </div>
          </div>
          <div className="w-full md:w-11/12 p-4">
            {showCategories && (
              <div className="w-full md:hidden p-4 border mb-4">
                {category && (
                  <>
                    <Link to={`/category/${categoryId}`}>
                      <h1>{i18n.language === 'ar' ? category.ar_name : category.en_name}</h1>
                      <div
                        style={{ backgroundImage: `url(${getFullImageUrl(category.image)})` }}
                        className="h-32 bg-cover bg-center mb-4"
                      ></div>
                    </Link>
                    <div className="grid grid-cols-1 gap-2">
                      {category.subcategories && renderSubcategories(category.subcategories)}
                    </div>
                  </>
                )}
              </div>
            )}
          <h1 className='text-center mb-4'>{i18n.t('Products')}</h1>
            <div>
              {categoryProducts.length === 0 ? (
                <div className="text-center text-red-500">{t('No_products_found_for_this_category.')}</div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                  {categoryProducts.map((product) => (
                    <div key={product.id} className="flex justify-center w-full">
                      <EcommerceCard
                        productId={product.id}
                        images={product.images}
                        title={i18n.language === 'ar' ? product.ar_title : product.en_title}
                        price={parseFloat(product.price)}
                        description={i18n.language === 'ar' ? product.ar_description : product.en_description}
                        discount={product.discount ? product.discount : 0}
                        discountType={product.discount_type}
                        discountedPrice={parseFloat(product.discounted_price)}
                        tagText={product.tag_text}
                        isNew={product.is_new}
                        tagColor={product.tag_color}
                        currency={product.currency}
                        favorite={product.favorite}
                        cart={product.cart}
                        stock={product.stock}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
                >
                  {t('previous')}
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={categoryProducts.length < itemsPerPage}
                  className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
                >
                  {t('next')}
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryProducts;
