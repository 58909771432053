import React from 'react';

const Pagination = ({ currentPage, totalPages, itemsPerPage, handlePageChange, handleItemsPerPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 mx-1 rounded ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex flex-wrap justify-center mt-4">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className="bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2 mb-2"
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <div className="flex flex-wrap justify-center mb-2">
        {renderPageNumbers()}
      </div>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className="bg-gray-300 text-gray-700 py-2 px-4 rounded ml-2 mb-2"
        disabled={currentPage === totalPages}
      >
        Next
      </button>
      <select
        value={itemsPerPage}
        onChange={handleItemsPerPageChange}
        className="ml-4 p-2 border rounded mb-2"
      >
        {[10, 15, 20, 25, 30].map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Pagination;
