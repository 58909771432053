import axiosInstance from '../utils/axiosInstance';

export const addToFavorites = async (product_id) => {
  

  try {
    const response = await axiosInstance.post('/favorites/add', { product_id });
    return response.data;
  } catch (error) {
    console.error('Error adding to favorites:', error);
    throw error;
  }
};

// Remove from favorites
export const removeFromFavorites = async (product_id) => {
  

  try {
    const response = await axiosInstance.post('/favorites/remove', { product_id });
    return response.data;
  } catch (error) {
    console.error('Error removing from favorites:', error);
    throw error;
  }
};

export const getFavorites = async () => {
  

  try {
    const response = await axiosInstance.get('/favorites');
    return response.data;
  } catch (error) {
    console.error('Error fetching favorites:', error);
    throw error;
  }
};
