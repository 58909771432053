// redux/reducers/notificationsReducer.js
import {
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_NOTIFICATION_BY_ID_REQUEST,
    FETCH_NOTIFICATION_BY_ID_SUCCESS,
    FETCH_NOTIFICATION_BY_ID_FAILURE,
    DELETE_NOTIFICATION_REQUEST,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_ALL_NOTIFICATIONS_REQUEST,
    DELETE_ALL_NOTIFICATIONS_SUCCESS,
    DELETE_ALL_NOTIFICATIONS_FAILURE,
    GET_USER_NOTIFICATIONS_COUNT_REQUEST,
    GET_USER_NOTIFICATIONS_COUNT_SUCCESS,
    GET_USER_NOTIFICATIONS_COUNT_FAILURE,
    GET_USER_NOTIFICATIONS_REQUEST,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_NOTIFICATIONS_FAILURE,
    MARK_NOTIFICATION_AS_READ_REQUEST,
    MARK_NOTIFICATION_AS_READ_SUCCESS,
    MARK_NOTIFICATION_AS_READ_FAILURE
  } from '../actions/notificationsActions';
  
  const initialState = {
    notifications: [],
    notification: null,
    loading: false,
    error: null,
    count: 0
  };
  
  const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_NOTIFICATIONS_REQUEST:
      case FETCH_NOTIFICATION_BY_ID_REQUEST:
      case DELETE_NOTIFICATION_REQUEST:
      case DELETE_ALL_NOTIFICATIONS_REQUEST:
      case GET_USER_NOTIFICATIONS_COUNT_REQUEST:
      case GET_USER_NOTIFICATIONS_REQUEST:
      case MARK_NOTIFICATION_AS_READ_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_NOTIFICATIONS_SUCCESS:
        return { ...state, loading: false, notifications: action.payload };
  
      case FETCH_NOTIFICATION_BY_ID_SUCCESS:
        return { ...state, loading: false, notification: action.payload };
  
      case DELETE_NOTIFICATION_SUCCESS:
        return { ...state, loading: false, notifications: state.notifications.filter(n => n.id !== action.payload) };
  
      case DELETE_ALL_NOTIFICATIONS_SUCCESS:
        return { ...state, loading: false, notifications: [] };
  
      case GET_USER_NOTIFICATIONS_COUNT_SUCCESS:
        return { ...state, loading: false, count: action.payload };
  
      case GET_USER_NOTIFICATIONS_SUCCESS:
        return { ...state, loading: false, notifications: action.payload };
  
      case MARK_NOTIFICATION_AS_READ_SUCCESS:
        return { ...state, loading: false, notifications: action.payload };
  
      case FETCH_NOTIFICATIONS_FAILURE:
      case FETCH_NOTIFICATION_BY_ID_FAILURE:
      case DELETE_NOTIFICATION_FAILURE:
      case DELETE_ALL_NOTIFICATIONS_FAILURE:
      case GET_USER_NOTIFICATIONS_COUNT_FAILURE:
      case GET_USER_NOTIFICATIONS_FAILURE:
      case MARK_NOTIFICATION_AS_READ_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default notificationsReducer;
  