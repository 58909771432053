import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCart, updateCartAction, removeFromCartAction } from '../../redux/actions/cartActions';
import { getFullImageUrl } from '../../utils/imageUtils';
import { useTranslation } from 'react-i18next';
import MetaTags from '../../components/MetaTags';
import CheckoutPage from './CheckoutPage';
import Pagination from '../../components/Pagination';

const CartPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const loading = useSelector((state) => state.cart.loading);
  const error = useSelector((state) => state.cart.error);
  const totalQuantity = useSelector((state) => state.cart.total_quantity);
  const totalPrice = useSelector((state) => state.cart.total_price);
  const totalPages = useSelector((state) => state.cart.last_page);
  const Currency = localStorage.getItem('selectedCurrency');
  const [localQuantities, setLocalQuantities] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchCart({ page: currentPage, per_page: itemsPerPage }));
  }, [dispatch, currentPage, itemsPerPage]);

  useEffect(() => {
    const quantities = cartItems.reduce((acc, item) => {
      acc[item.cart_item_id] = item.quantity;
      return acc;
    }, {});
    setLocalQuantities(quantities);
  }, [cartItems]);

  const handleUpdateQuantity = (itemId, productId, quantity) => {
    if (quantity < 1) return;
    dispatch(updateCartAction(itemId, productId, quantity));
  };

  const handleQuantityChange = (itemId, value) => {
    setLocalQuantities({
      ...localQuantities,
      [itemId]: value,
    });
  };

  const handleRemoveItem = (itemId, productId) => {
    dispatch(removeFromCartAction(itemId, productId));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page change
  };

  return (
    <>
      <MetaTags title={t('Cart')} />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-4">{t('cart.title')}</h1>
        {loading && <p>{t('cart.loading')}</p>}
        {error && <p>{t('cart.error')} {error}</p>}
        {cartItems.length === 0 ? (
          <div className="text-center text-lg">{t('cart.empty')}</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="py-2">{t('cart.product_image')}</th>
                  <th className="py-2">{t('cart.product_name')}</th>
                  <th className="py-2">{t('cart.quantity')}</th>
                  <th className="py-2">{t('cart.price')}</th>
                  <th className="py-2">{t('cart.total_price')}</th>
                  <th className="py-2">{t('cart.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.cart_item_id} className="border-t">
                    <td className="py-2">
                      <img src={getFullImageUrl(item.product_image)} alt={item.product_ar_title} className="w-16 h-16 object-cover" />
                    </td>
                    <td className="py-2">{i18n.language === 'ar' ? item.product_ar_title : item.product_en_title}</td>
                    <td className="py-2">
                      <div className="flex items-center">
                        <input
                          type="number"
                          value={localQuantities[item.cart_item_id]}
                          onChange={(e) => handleQuantityChange(item.cart_item_id, parseInt(e.target.value))}
                          onBlur={() => handleUpdateQuantity(item.cart_item_id, item.product_id, localQuantities[item.cart_item_id])}
                          className="border p-2 rounded text-center w-16"
                          min="1"
                        />
                      </div>
                    </td>
                    <td className="py-2">{Currency} - {item.product_price}</td>
                    <td className="py-2">{Currency} - {item.total_price}</td>
                    <td className="py-2">
                      <button
                        onClick={() => handleRemoveItem(item.cart_item_id, item.product_id)}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                      >
                        {t('cart.remove')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="border-t">
                  <td className="py-2 font-bold" colSpan="4">{t('cart.total_quantity')}:</td>
                  <td className="py-2 font-bold">{totalQuantity}</td>
                  <td className="py-2"></td>
                </tr>
                <tr className="border-t">
                  <td className="py-2 font-bold" colSpan="4">{t('cart.total_price')}:</td>
                  <td className="py-2 font-bold">{Currency} - {totalPrice}</td>
                  <td className="py-2"></td>
                </tr>
              </tfoot>
            </table>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              handlePageChange={handlePageChange}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
            <CheckoutPage currency={Currency} />
          </div>
        )}
      </div>
    </>
  );
};

export default CartPage;
