import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import cartReducer from './reducers/cartReducer';
import productReducer from './reducers/productReducer';
import categoryReducer from './reducers/categoryReducer';
import appReducer from './reducers/appReducer';
import favoriteReducer from './reducers/favoriteReducer';
import orderReducer  from './reducers/orderReducer';
import adsReducer from './reducers/adsReducer';
import notificationsReducer from './reducers/notificationsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  products: productReducer,
  category: categoryReducer,
  notifications: notificationsReducer,
  app: appReducer, 
  ads: adsReducer, 
  favorites: favoriteReducer,
  orders: orderReducer,
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
