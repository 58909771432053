import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DiscountCodeModal = ({ show, handleClose, discountCode, setDiscountCode, handleApplyDiscountCode }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Apply Discount Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          className="w-full border p-2 rounded mb-4"
          type="text"
          placeholder="Enter Discount Code"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleApplyDiscountCode}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscountCodeModal;
