import { FETCH_USER_PROFILE, UPDATE_USER_PROFILE, LOGIN_SUCCESS, LOGOUT } from '../actions/authActions';
import Cookies from 'js-cookie';

// Helper function to safely parse JSON
const safeJSONParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

const initialState = {
  token: Cookies.get('authToken') || null,
  user: safeJSONParse(Cookies.get('authUser')) || null,
  profile: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      Cookies.set('authToken', action.payload.token, { expires: 30 });
      Cookies.set('authUser', JSON.stringify(action.payload.user), { expires: 30 });
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    case LOGOUT:
      Cookies.remove('authToken');
      Cookies.remove('authUser');
      return {
        ...state,
        token: null,
        user: null,
        profile: null,
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        user: action.payload.user,
        profile: action.payload,
      };
    case UPDATE_USER_PROFILE:
      Cookies.set('authUser', JSON.stringify(action.payload), { expires: 30 });
      return {
        ...state,
        user: action.payload,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
