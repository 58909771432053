import { getCategories, getCategoriesWithAllChildren , getCategoryById } from '../../services/categoryApi';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORIES_WITH_CHILDREN_REQUEST = 'FETCH_CATEGORIES_WITH_CHILDREN_REQUEST';
export const FETCH_CATEGORIES_WITH_CHILDREN_SUCCESS = 'FETCH_CATEGORIES_WITH_CHILDREN_SUCCESS';
export const FETCH_CATEGORIES_WITH_CHILDREN_FAILURE = 'FETCH_CATEGORIES_WITH_CHILDREN_FAILURE';
export const FETCH_CATEGORY_BY_ID_REQUEST = 'FETCH_CATEGORY_BY_ID_REQUEST';
export const FETCH_CATEGORY_BY_ID_SUCCESS = 'FETCH_CATEGORY_BY_ID_SUCCESS';
export const FETCH_CATEGORY_BY_ID_FAILURE = 'FETCH_CATEGORY_BY_ID_FAILURE';


export const fetchCategoriesRequest = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (categories) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: categories,
});

export const fetchCategoriesFailure = (error) => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload: error,
});

export const fetchCategoriesWithChildrenRequest = () => ({
  type: FETCH_CATEGORIES_WITH_CHILDREN_REQUEST,
});

export const fetchCategoriesWithChildrenSuccess = (categories) => ({
  type: FETCH_CATEGORIES_WITH_CHILDREN_SUCCESS,
  payload: categories,
});

export const fetchCategoriesWithChildrenFailure = (error) => ({
  type: FETCH_CATEGORIES_WITH_CHILDREN_FAILURE,
  payload: error,
});

export const fetchCategoryByIdRequest = () => ({
  type: FETCH_CATEGORY_BY_ID_REQUEST,
});

export const fetchCategoryByIdSuccess = (category) => ({
  type: FETCH_CATEGORY_BY_ID_SUCCESS,
  payload: category,
});

export const fetchCategoryByIdFailure = (error) => ({
  type: FETCH_CATEGORY_BY_ID_FAILURE,
  payload: error,
});


export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch(fetchCategoriesRequest());
    try {
      const categories = await getCategories();
      dispatch(fetchCategoriesSuccess(categories));
    } catch (error) {
      dispatch(fetchCategoriesFailure(error.message));
    }
  };
};

export const fetchCategoryById = (id) => {
  return async (dispatch) => {
    dispatch(fetchCategoryByIdRequest());
    try {
      const category = await getCategoryById(id);
      dispatch(fetchCategoryByIdSuccess(category));
    } catch (error) {
      dispatch(fetchCategoryByIdFailure(error.message));
    }
  };
};


export const fetchCategoriesWithChildren = () => {
  return async (dispatch) => {
    dispatch(fetchCategoriesWithChildrenRequest());
    try {
      const categories = await getCategoriesWithAllChildren();
      dispatch(fetchCategoriesWithChildrenSuccess(categories));
    } catch (error) {
      dispatch(fetchCategoriesWithChildrenFailure(error.message));
    }
  };
};
