import {
  FETCH_FAVORITES_REQUEST,
  FETCH_FAVORITES_SUCCESS,
  FETCH_FAVORITES_FAILURE,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAILURE,
  REMOVE_FAVORITE_REQUEST,
  REMOVE_FAVORITE_SUCCESS,
  REMOVE_FAVORITE_FAILURE
} from '../actions/favoriteActions';

const initialState = {
  favorites: [],
  loading: false,
  error: null
};

const favoriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAVORITES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_FAVORITES_SUCCESS:
      return { ...state, loading: false, favorites: action.payload };
    case FETCH_FAVORITES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_FAVORITE_REQUEST:
      return { ...state, loading: true, error: null };
    case ADD_FAVORITE_SUCCESS:
      return { ...state, loading: false, favorites: [...state.favorites, action.payload] };
    case ADD_FAVORITE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case REMOVE_FAVORITE_REQUEST:
      return { ...state, loading: true, error: null };
    case REMOVE_FAVORITE_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        favorites: state.favorites.filter(favorite => favorite.id !== action.payload) 
      };
    case REMOVE_FAVORITE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default favoriteReducer;
